import {
  Divider,
  Flex,
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { EntriesFound, Paginator, SimpleEntityList, VStack } from 'components';
import { Stats } from 'components/Stats/Stats';
import SiteControlEntity from 'entities/api/SiteControl';
import SiteFrameworkEntity, {
  type SiteFrameworkEntityRecord,
} from 'entities/api/SiteFramework';
import { type LinkTargetCallback } from 'screens/control-frameworks/components/ControlCard';
import { ControlCardWithStats } from 'screens/control-frameworks/components/ControlCardWithStats';
import { FrameworksList } from 'screens/control-frameworks/components/FrameworksList';
import { createLogger } from 'util/createLogger';

import { useDashboardControls } from './ControlDashboard.hooks';
import { controlCardsGridSx } from './ControlDashboard.styles';
import { CriticalControlLegend } from './CriticalControlLegend';
import { ControlDashboardFilters } from './DashboardFilterForm/DashboardFilterForm';

const log = createLogger('ControlDashboard');

export type FindFrameworkForSiteControl = (
  controlUuid: string,
) => SiteFrameworkEntityRecord | undefined;

export function ControlDashboard() {
  const {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    localeContext,
    stats,
    siteFrameworkParams,
    siteFrameworkPaginatorProps,
    controlParams,
    controlPaginatorProps,
  } = useDashboardControls();

  const siteFrameworks = useSelector<unknown, List<SiteFrameworkEntityRecord>>(
    (state) =>
      SiteFrameworkEntity.duck.selectors.record(state, {
        params: siteFrameworkParams,
      }),
  );

  log('%o', { siteFrameworks });

  const findFrameworkForSiteControl = useCallback(
    (controlUuid: string) =>
      siteFrameworks?.find(
        (sf) =>
          !!sf?.get('controls')?.find((c) => c.get('uuid') == controlUuid),
      ),
    [siteFrameworks],
  );

  const controlCardLinkTargetCb = useCallback<LinkTargetCallback>(
    (controlUuid) =>
      `/control-frameworks/${
        findFrameworkForSiteControl(controlUuid)?.get('uuid') ??
        ':siteFrameworkUuid'
      }/controls/${controlUuid}/`,
    [findFrameworkForSiteControl],
  );

  const site_control_owner = filterRecord?.get(
    'siteframeworkcontrol__sitecontrolstatus__control_owner',
  );

  if (!site_control_owner) return null;

  return (
    <Flex direction="column" gap={8}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="5">
        <GridItem colSpan={3}>
          <Heading size="lg">
            <FormattedMessage
              id="dashboard.my-controls.title"
              defaultMessage="My controls performance"
            />
          </Heading>
        </GridItem>

        <ControlDashboardFilters
          name={name}
          filterRecord={filterRecord}
          onChange={onChange}
          FilterField={FilterField}
          errors={errors}
        />
      </SimpleGrid>

      <Stats
        stats={stats}
        filterRecord={filterRecord}
        site_control_owner={site_control_owner}
      />

      <Divider />

      <Heading as="h3" size="sm">
        <FormattedMessage
          id="dashboard.my-controls.list.my-controls.heading"
          defaultMessage="My controls"
        />
      </Heading>

      <VStack gap={4}>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <EntriesFound count={controlPaginatorProps.totalItems} />

          <CriticalControlLegend
            onChange={onChange}
            value={filterRecord.get('is_critical')}
          />
        </Stack>

        <SimpleGrid
          templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
          sx={controlCardsGridSx}
        >
          <SimpleEntityList
            Entity={SiteControlEntity}
            RecordComponent={ControlCardWithStats}
            recordComponentProps={{
              localeContext,
              linkTarget: controlCardLinkTargetCb,
              extraActivityListParams: {
                site_control_owner,
                filterRecord,
                findFrameworkForSiteControl,
              },
            }}
            params={controlParams}
          />
        </SimpleGrid>
      </VStack>

      <Paginator displayAlways={false} {...controlPaginatorProps} />

      <Divider />

      <Heading as="h3" size="sm">
        <FormattedMessage
          id="dashboard.my-controls.list.my-control-frameworks.heading"
          defaultMessage="My related control frameworks"
        />
      </Heading>

      <VStack gap={4}>
        <EntriesFound count={siteFrameworkPaginatorProps.totalItems} />

        <FrameworksList
          filterRecord={filterRecord}
          params={siteFrameworkParams}
          refreshList={0}
          showStats={true}
        />
      </VStack>

      <Paginator displayAlways={false} {...siteFrameworkPaginatorProps} />
    </Flex>
  );
}
