import environmentSettings from './environments';

const BASE_API_ORIGIN = 'http://localhost:8000';

/**
 * Base settings
 *
 * When adding new settings on a per-env basis, ensure that they're added here
 * first to ensure type safety and assist IDE integration.
 */
export const baseSettings = {
  ENVIRONMENT: import.meta.env.MODE,

  BASE_API_ORIGIN,
  /** pathname prefix required to construct an external URL due to our use of HashRouter */
  APP_ROOT_PATHNAME: `${globalThis.location.origin}/#`,

  // axiosInstance provides this as baseURL
  BASE_API_URL: `${BASE_API_ORIGIN}/api/v1`,
  LOGIN_URL: `${BASE_API_ORIGIN}/signin/checklogin/`,
  LOGOUT_URL: `${BASE_API_ORIGIN}/signin/logout/`,

  // assumes you're running cube on :9001; no harm if this 404s in dev
  FLEX_URL: 'http://localhost:9000/#',

  // these URLs are NOT based on BASE_API_URL, so are composed after merging
  // environmentSettings; they are stubbed here for the sake of TS
  AUTH_URL: '', // composed below
  FILE_UPLOAD_URL: '', // composed below
  SESSION_CHECK_INTERVAL: 60000,
  CSRF_COOKIE_NAME: 'csrftoken',
  SENTRY_DSN:
    'https://cc7e4badec017e504725cf8dcf524f3c@o21780.ingest.us.sentry.io/4507258757185536',

  // max attachment size in bytes
  MAX_FILE_SIZE: 20971520,

  // attachment restrictions
  FILE_TYPE_RESTRICTIONS: {
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      ['.pptx'],
    'application/pdf': ['.pdf'],
    'text/plain': ['.txt'],
    'audio/mp3': ['.mp3'],
    'audio/mp4': ['.mp4'],
    'audio/wav': ['.wav'],
    'video/mpeg': ['.mpeg', '.mpg'],
    'video/mov': ['.mov'],
    'video/wmv': ['.wmv'],
  },
};

/** App settings with per-env overrides applied  */
export const settings = {
  ...baseSettings,
  ...environmentSettings?.[
    import.meta.env.MODE as keyof typeof environmentSettings
  ],
};

// these variables require environmentSettings to be merged before they can be
// composed
settings.BASE_API_URL = `${settings.BASE_API_ORIGIN}/api`;
settings.AUTH_URL = `${settings.BASE_API_ORIGIN}/api/auth/v1/`;
settings.FILE_UPLOAD_URL = `${settings.BASE_API_ORIGIN}/api/br_filer/v1/file`;
settings.LOGIN_URL = `${settings.BASE_API_ORIGIN}/signin/checklogin/`;
settings.LOGOUT_URL = `${settings.BASE_API_ORIGIN}/signin/logout/`;

export type Settings = typeof baseSettings;
