import { type List, Map } from 'immutable';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router';

import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import EquipmentEntity from 'entities/api/Equipment';
import OperatingContextEntity, {
  type OperatingContextEntityRecord,
} from 'entities/api/OperatingContext';
import PersonEntity, { useCurrentUser } from 'entities/api/Person/Person';
import GlobalVisualisationFilterEntity, {
  type GlobalVisualisationFilterEntityRecord,
} from 'entities/filter/GlobalVisualisationFilter';
import { useEntityFilterControls } from 'forms/hooks/filters';
import { useLocale } from 'locales/useLocale';

import { type GlobalFrameworkContext } from '../GlobalFrameworks';

export function useControlVisualisationControls() {
  const { framework } = useOutletContext<GlobalFrameworkContext>();
  const { toString } = useLocale();
  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof GlobalVisualisationFilterEntity,
      GlobalVisualisationFilterEntityRecord
    >(
      GlobalVisualisationFilterEntity,
      'GlobalDashboardFilters',
      GlobalVisualisationFilterEntity.onChangeClean,
    );
  const riskId = useMemo(() => {
    return framework?.get('risk')?.get('uuid') ?? '';
  }, [framework]);
  const params = useMemo(() => {
    return GlobalVisualisationFilterEntity.asMappedParams(filterRecord).set(
      'applicable_rules__risk',
      riskId,
    );
  }, [filterRecord, riskId]);
  // // Clear the filters if the framework change
  useEffect(() => {
    if (riskId) {
      onChange({
        target: {
          name: name,
          value: GlobalVisualisationFilterEntity.dataToRecord({}),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskId]);
  const user = useCurrentUser();

  /** Initial options equipment for the 'Equipment L1 filter' */
  const equipmentLevel1 = useSelector<unknown, List<EquipmentEntityRecord>>(
    (state) =>
      EquipmentEntity.duck.selectors.record(state, {
        params: Map({
          predicates__rule__risk: riskId || undefined,
          level: '1',
        }),
      }),
  );

  /** Initial options equipment for the 'Equipment L2 filter' */
  const equipmentLevel2 = useSelector<unknown, List<EquipmentEntityRecord>>(
    (state) =>
      EquipmentEntity.duck.selectors.record(state, {
        params: Map({
          predicates__rule__risk: riskId || undefined,
          // note: in order get the initial options don't look up state indexed by the parents__in param!
          // parents__in: GlobalVisualisationFilterEntity.getUuidList(
          //   filterRecord?.get('equipment_l1'),
          // ),
          level: '2',
        }),
      }),
  );

  /** Initial options for the 'Operating Context filter' */
  const operatingContexts = useSelector<
    unknown,
    List<OperatingContextEntityRecord>
  >((state) =>
    OperatingContextEntity.duck.selectors.record(state, {
      params: Map({
        globalframework__risk: riskId || undefined,
        predicates__rule__risk: riskId || undefined,
      }),
    }),
  );

  return {
    toString,
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    params,
    framework,
    riskId,
    isPublicUser: PersonEntity.isPublicUser(user),
    equipmentLevel1,
    equipmentLevel2,
    operatingContexts,
  };
}

export type UseControlVisualisationControlsReturn = ReturnType<
  typeof useControlVisualisationControls
>;
