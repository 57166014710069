import type { SystemStyleObject } from '@chakra-ui/react';

export const normalizeTh: SystemStyleObject = {
  textTransform: 'none',
  fontWeight: 'normal',
  color: 'bodyText',
};

export const tableSx: SystemStyleObject = {
  borderSpacing: '2px',
  borderCollapse: 'separate',

  thead: {
    th: {
      width: '125px',
      padding: 2,
      bgColor: 'gray.300',
      borderColor: 'transparent',

      fontSize: 'xs',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      ...normalizeTh,

      '&.blank': {
        bgColor: 'transparent',
        borderColor: 'transparent',
      },

      '&.isFilteredColumn': { bgColor: 'orange.200' },
    },
  },

  tbody: {
    'th,td': {
      paddingY: 2,
      paddingX: 1,
      bgColor: 'gray.200',
      borderColor: 'transparent',
      fontSize: 'xs',
      textAlign: 'center',
      whiteSpace: 'break-spaces',

      '&.scenarios': {
        width: '33%',
        verticalAlign: 'top',
        textAlign: 'left',
      },

      '&.isFilteredRow': { bgColor: 'orange.200' },
      '&.isFilteredColumn': { bgColor: 'orange.200' },
      '&.isFilteredRow.isFilteredColumn': {
        bgColor: 'orange.500',
        color: 'white',
      },
    },

    th: {
      bgColor: 'gray.300',
      ...normalizeTh,

      '&.equipment-l2': { bgColor: 'gray.200' },
      '&.isFilteredRow': { bgColor: 'orange.200' }, // un-clobber .equipment-l2 bgColor
    },
  },
};
