import {
  Box,
  Card,
  Flex,
  Grid,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import type { Moment } from 'moment';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import type { PersonEntityRecord } from 'entities/api/Person/Person';
import { type SiteControlEntityRecord } from 'entities/api/SiteControl';
import type { ActivityFilterEntityFields } from 'entities/filter/ActivityFilter';
import type { ControlDashboardFilterEntityRecord } from 'entities/filter/ControlDashboardFilter';
import {
  activityListFiltersReset,
  useActivityListControls,
} from 'screens/activities/components';
import type { FindFrameworkForSiteControl } from 'screens/dashboards/Control/ControlDashboard';
import { createLogger } from 'util/createLogger';

import { ControlCard, type ControlCardProps } from './ControlCard';
import {
  headingSx,
  labelSx,
  miniStatCard,
  statsFlexSx,
} from './ControlCardWithStats.styles';

const log = createLogger('ControlCardWithStats');

export type ControlCardWithStatsProps = Exclude<ControlCardProps, 'record'> & {
  record: SiteControlEntityRecord;
  extraActivityListParams: {
    site_control_owner: PersonEntityRecord;
    filterRecord: ControlDashboardFilterEntityRecord;
    findFrameworkForSiteControl: FindFrameworkForSiteControl;
  };
};

export const ControlCardWithStats = (props: ControlCardWithStatsProps) => {
  const { createHandleNavigateToActivityList } = useActivityListControls();
  const {
    record,
    extraActivityListParams: {
      site_control_owner,
      filterRecord,
      findFrameworkForSiteControl,
    },
    ...controlCardProps
  } = props;
  log('props %o', props);

  const countCCC = record?.get('count_ccc') ?? 0;
  const countCC = record?.get('count_cc') ?? 0;
  const countInterruptions = record?.get('count_interruptions') ?? 0;
  const countFixes = record?.get('count_fix') ?? 0;
  const countActions = record?.get('cc_with_actions') ?? 0;

  /** set defaults and overrides for ActivityListFilters */
  const commonActivityListFilters = {
    ...activityListFiltersReset,
    risk: findFrameworkForSiteControl(record.get('uuid'))?.get('risk'),
    observation_date__gte: filterRecord?.get('with_statistics__gte') as Moment,
    site_control: record,
    site_control_owner,
  } satisfies Partial<ActivityFilterEntityFields>;

  return (
    <ControlCard
      {...controlCardProps}
      record={record}
      stats={
        <Box>
          <Flex sx={statsFlexSx} justifyContent="space-between">
            {/* CCC Stats */}
            <Card as={Stat} variant="unstyled" flex="6" sx={miniStatCard}>
              <StatLabel sx={headingSx}>
                <FormattedMessage
                  id="ControlCardWithStats.ccc"
                  defaultMessage="CCC"
                />
              </StatLabel>

              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <Box
                  onClick={createHandleNavigateToActivityList({
                    ...commonActivityListFilters,
                    observation_types: ['CriticalControlCheck'],
                  })}
                  layerStyle="hoverPointer"
                >
                  <StatNumber>
                    <FormattedNumber value={countCCC} />
                  </StatNumber>
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.ccc.total"
                      defaultMessage="Total"
                    />
                  </StatHelpText>
                </Box>

                <Box
                  onClick={createHandleNavigateToActivityList({
                    ...commonActivityListFilters,
                    observation_types: ['CriticalControlCheck'],
                    CriticalControlCheck__has_interruption: true,
                  })}
                  layerStyle="hoverPointer"
                >
                  <StatNumber>
                    <FormattedNumber value={countInterruptions} />
                  </StatNumber>
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.ccc.interrupts"
                      defaultMessage="Interrupts"
                    />
                  </StatHelpText>
                </Box>

                <Box
                  onClick={createHandleNavigateToActivityList({
                    ...commonActivityListFilters,
                    observation_types: ['CriticalControlCheck'],
                    CriticalControlCheck__has_fixed: true,
                  })}
                  layerStyle="hoverPointer"
                >
                  <StatNumber>
                    <FormattedNumber value={countFixes} />
                  </StatNumber>
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.ccc.fix"
                      defaultMessage="Fix"
                    />
                  </StatHelpText>
                </Box>
              </Grid>
            </Card>

            {/* FCC Stats */}
            <Card as={Stat} variant="unstyled" flex="4" sx={miniStatCard}>
              <StatLabel sx={headingSx}>
                <FormattedMessage
                  id="ControlCardWithStats.fcc"
                  defaultMessage="FCC"
                />
              </StatLabel>

              <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                <Box
                  onClick={createHandleNavigateToActivityList({
                    ...commonActivityListFilters,
                    observation_types: ['ControlConfirmation'],
                  })}
                  layerStyle="hoverPointer"
                >
                  <StatNumber>
                    <FormattedNumber value={countCC} />
                  </StatNumber>
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.fcc.total"
                      defaultMessage="Total"
                    />
                  </StatHelpText>
                </Box>

                <Box
                  onClick={createHandleNavigateToActivityList({
                    ...commonActivityListFilters,
                    observation_types: ['ControlConfirmation'],
                    are_follow_up_actions_required: true,
                  })}
                  layerStyle="hoverPointer"
                >
                  <StatNumber>
                    <FormattedNumber value={countActions} />
                  </StatNumber>
                  <StatHelpText sx={labelSx}>
                    <FormattedMessage
                      id="ControlCardWithStats.fcc.actions"
                      defaultMessage="Actions"
                    />
                  </StatHelpText>
                </Box>
              </Grid>
            </Card>
          </Flex>
        </Box>
      }
    />
  );
};
