import { Select, type SelectProps } from '@chakra-ui/react';
import { type Moment } from 'moment';
import { useIntl } from 'react-intl';

import { type PeriodOption } from 'entities/filter/periodOptions';
import { type EntityDuckAction, type FormChangeEvent } from 'types';

import { usePeriodSelectControls } from './PeriodSelect.hooks';

type PeriodSelectProps = {
  name: string;
  onChange: (event: FormChangeEvent) => EntityDuckAction;
  options: PeriodOption[];
  value: Moment;
} & Pick<SelectProps, 'size' | 'variant' | 'colorScheme' | 'sx'>;

export const PeriodSelect = ({
  name,
  onChange,
  options,
  value,
  size,
  variant,
  colorScheme,
  sx,
}: PeriodSelectProps) => {
  const { formatMessage } = useIntl();
  const { selectOnChange } = usePeriodSelectControls(name, onChange);

  return (
    <Select
      value={value.toISOString()}
      onChange={selectOnChange}
      size={size}
      variant={variant}
      colorScheme={colorScheme}
      sx={sx}
    >
      {options &&
        options.map(({ value, label }, idx) => (
          <option key={idx} value={value.toISOString()}>
            {formatMessage(label)}
          </option>
        ))}
    </Select>
  );
};
