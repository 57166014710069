import type { Moment } from 'moment';
import { defineMessage } from 'react-intl';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import I18nTextEntity, {
  type I18nTextEntityRecord,
} from 'entities/api/i18n/I18nText';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from 'entities/api/Person/GrcOrganisationalUnit';
import PersonEntity, {
  type PersonEntityRecord,
} from 'entities/api/Person/Person';
import { type EntityFields, type EntityRecord } from 'types';

class FieldObservationReport extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/bhp_field_leadership/reporting/v2/field_observation_report/',
  };

  static fields: EntityFields<FieldObservationReportFields> = {
    uuid: new Fields.IdField(),
    observation_type_short_name: new Fields.CharField(),
    grc_organisational_unit: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
    }),
    lead_observer: new Fields.EntityField({
      entity: PersonEntity,
    }),
    are_follow_up_actions_required: new Fields.BooleanField(),
    created: new Fields.DateTimeField(),
    observation_date: new Fields.DateField(),
    control_framework_title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
    actions_count: new Fields.IntegerField(),
    interruptions_count: new Fields.IntegerField(),
    fixes_count: new Fields.IntegerField(),
  };
}

export type FieldObservationReportFields = {
  uuid: string;
  observation_type_short_name: ObservationTypeShortName;
  /** TODO update API to return `ancestors` */
  grc_organisational_unit: GrcOrganisationalUnitEntityRecord;
  /** FIXME not actually a PersonEntityRecord, but shares the fields we care about */
  lead_observer: PersonEntityRecord;
  are_follow_up_actions_required: boolean;
  created: Moment;
  observation_date: Moment;
  control_framework_title: I18nTextEntityRecord;
  actions_count: number;
  interruptions_count: number;
  fixes_count: number;
};

export type FieldObservationReportRecord =
  EntityRecord<FieldObservationReportFields>;

FieldObservationReport.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: FieldObservationReport,
  name: 'FieldObservationReport',
});

export default FieldObservationReport;

/**
 * In django, FieldObservation names are constructed from model names, and no
 * translations have been implemented yet.
 */
export const observationTypes = [
  'CriticalControlCheck',
  'ControlConfirmation',
] as const;
export type ObservationType = (typeof observationTypes)[number];

export const observationTypeShortNames = ['CCC', 'FCC'] as const;
export type ObservationTypeShortName =
  (typeof observationTypeShortNames)[number];

/**
 * In django, FieldObservation names are constructed from model names, and no
 * translations have been implemented yet.
 */
export const observationTypeShortNameTranslations = {
  CCC: defineMessage({
    id: 'FieldObservationReport.observation_type_short_name.ccc',
    defaultMessage: 'CCC',
  }),
  FCC: defineMessage({
    id: 'FieldObservationReport.observation_type_short_name.cc',
    defaultMessage: 'FCC',
  }),
} satisfies {
  [k in ObservationTypeShortName]: ReturnType<typeof defineMessage>;
};
