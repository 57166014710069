import {
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import { type List } from 'immutable';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';

import type { RulePredicateEntityRecord } from 'entities/api/RulePredicate';
import { useLocale } from 'locales/useLocale';
import type { UseControlVisualisationControlsReturn } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation.hooks';

import type { ExtendedApplicableRuleEntityRecord } from './ControlPreview';
import { useMandatoryAssessTable } from './MandatoryAssessTable.hooks';
import { tableSx } from './MandatoryAssessTable.styles';

const mandatory = defineMessage({
  id: 'MandatoryAssessTable.mandatory',
  defaultMessage: 'Mandatory',
});
const assess = defineMessage({
  id: 'MandatoryAssessTable.assess',
  defaultMessage: 'Assess',
});

export type MandatoryAssessTableProps = {
  applicableRules: List<ExtendedApplicableRuleEntityRecord>;
  scenarios: List<RulePredicateEntityRecord>;
  filterRecord: UseControlVisualisationControlsReturn['filterRecord'];
  equipmentLevel1: UseControlVisualisationControlsReturn['equipmentLevel1'];
  equipmentLevel2: UseControlVisualisationControlsReturn['equipmentLevel2'];
  operatingContexts: UseControlVisualisationControlsReturn['operatingContexts'];
};

export const MandatoryAssessTable = ({
  applicableRules,
  scenarios,
  filterRecord,
  equipmentLevel1,
  equipmentLevel2,
  operatingContexts,
}: MandatoryAssessTableProps) => {
  const { toString } = useLocale();
  const { formatMessage } = useIntl();

  const {
    tableData,
    equipmentsFiltered,
    operatingContextsFiltered,
    scenarioTitles,
  } = useMandatoryAssessTable({
    applicableRules,
    scenarios,
    filterRecord,
    equipmentLevel1,
    equipmentLevel2,
    operatingContexts,
  });

  return (
    <TableContainer>
      <Table size="sm" sx={tableSx}>
        <Thead>
          <Tr>
            <Th className="blank" />
            <Th className="blank" />
            {operatingContexts.map((opCtx, i) => (
              <Th
                key={i}
                className={
                  operatingContextsFiltered.includes(opCtx)
                    ? 'isFilteredColumn'
                    : ''
                }
              >
                {toString(opCtx)}
              </Th>
            ))}
            <Th>
              <FormattedMessage
                id="MandatoryAssessTable.thead.scenarios"
                defaultMessage="List of scenarios for this control"
              />
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {tableData.map((row, i, all) => {
            const isL1RowHighlighted = equipmentsFiltered?.includes(
              row.get('equipmentL1'),
            );
            const isL2RowHighlighted = equipmentsFiltered?.includes(
              row.get('equipmentL2'),
            );

            const shouldShowHeaderRow = row.get('childIndex') == 0;

            return (
              <Tr key={i}>
                {shouldShowHeaderRow && (
                  <Th
                    rowSpan={row.get('childCount')}
                    className={isL1RowHighlighted ? 'isFilteredRow' : ''}
                  >
                    {toString(row.get('equipmentL1'))}
                  </Th>
                )}

                <Th
                  className={[
                    'equipment-l2',
                    isL2RowHighlighted ? 'isFilteredRow' : '',
                  ].join(' ')}
                >
                  {toString(row.get('equipmentL2'))}
                </Th>

                {operatingContexts.map((opCtx, j) => (
                  <Td
                    key={j}
                    className={[
                      isL1RowHighlighted || isL2RowHighlighted
                        ? 'isFilteredRow'
                        : '',
                      operatingContextsFiltered.includes(opCtx)
                        ? 'isFilteredColumn'
                        : '',
                    ].join(' ')}
                  >
                    {row.get(toString(opCtx)).count() ? (
                      <Text as="span">
                        {`${
                          row
                            .get(toString(opCtx))
                            .some((rule) => rule.get('is_mandatory'))
                            ? formatMessage(mandatory)
                            : formatMessage(assess)
                        }${row.get(toString(opCtx)).count() > 1 ? ' *' : ''}`}
                      </Text>
                    ) : (
                      '-'
                    )}
                  </Td>
                ))}

                {i == 0 && (
                  <Td rowSpan={all.count()} className="scenarios">
                    <UnorderedList>
                      {scenarioTitles.map((title: string, j: number) => (
                        <ListItem key={j}>{title}</ListItem>
                      ))}
                    </UnorderedList>
                  </Td>
                )}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
