import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types.ts';

import I18nTextEntity, { toString } from './i18n/I18nText';
import { type TitleEntityFields } from './types.ts';

class SiteFrameworkSlimEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_framework_as_options/',
  };

  static fields: EntityFields<SiteFrameworkSlimEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),
  };

  static toString = toString<SiteFrameworkSlimEntityRecord>;
}

type SiteFrameworkSlimEntityFields = TitleEntityFields;
export type SiteFrameworkSlimEntityRecord =
  EntityRecord<SiteFrameworkSlimEntityFields>;

SiteFrameworkSlimEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteFrameworkSlimEntity,
  name: 'SiteFrameworkSlim',
});

export default SiteFrameworkSlimEntity;
