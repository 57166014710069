import { type Settings } from 'settings';

/** production settings overrides */
export const settings: Partial<Settings> = {
  BASE_API_ORIGIN: 'https://evolve.bhp.com',
  CSRF_COOKIE_NAME: 'BURNSRED_EVOLVE_BHP_CSRF_COOKIE',

  FLEX_URL: 'https://flex.bhp.com/#',
};

export default settings;
