import { GridItem, HStack, Icon, Text } from '@chakra-ui/react';

import { icons } from 'assets/icons';
import { Tooltip } from 'components/atoms';
import { useLocale } from 'locales/useLocale';
import type { CommonControlEntityRecord } from 'screens/global-frameworks/ControlsVisualisation/ControlsVisualisation';

import { cellSx, gridCellSx } from './ControlGridCell.styles';

type ControlGridCellProps = {
  control: CommonControlEntityRecord;
  colStart: number;
  colEnd: number;
  onClick: VoidFunction;
  isExpanded: boolean;
};

export function ControlGridCell({
  control,
  colStart,
  colEnd,
  onClick,
  isExpanded,
}: ControlGridCellProps) {
  const { toString } = useLocale();

  return (
    <GridItem
      colStart={colStart}
      colEnd={colEnd}
      onClick={onClick}
      sx={gridCellSx}
      className={['ControlGridCell', isExpanded ? 'active' : ''].join(' ')}
      role="listitem"
    >
      <Tooltip
        label={<Text>{toString(control)}</Text>}
        modifiers={[
          {
            // prevent tooltip overflowing, causing horizontal scrolling
            name: 'preventOverflow',
            options: {
              boundary: document.querySelector('.ControlGrid'),
            },
          },
        ]}
      >
        <HStack
          sx={{
            bgColor: `controlType_${control?.get('control_designation')?.get('order')}`,
            ...cellSx,
          }}
          // layerStyle="truncate"
        >
          <Text as="span" layerStyle="truncate">
            {toString(control)}
          </Text>

          <Icon
            as={
              isExpanded ? icons.MdOutlineExpandLess : icons.MdOutlineExpandMore
            }
            boxSize={4}
          />
        </HStack>
      </Tooltip>
    </GridItem>
  );
}
