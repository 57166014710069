import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { VStack } from 'components';
import SiteControlEntity, {
  type SiteControlEntityRecord,
} from 'entities/api/SiteControl';
import { type SiteFrameworkEntityRecord } from 'entities/api/SiteFramework';
import { useLocale } from 'locales/useLocale';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

import type { FrameworkOutletContext } from '../ControlFramework';

export type ControlDetailContextType = {
  siteFramework: SiteFrameworkEntityRecord;
  controlFormControls: ControlDetailProps;
};

const log = createLogger('ControlDetail');

const pathMap = {
  settings: 0,
  rules: 1,
  ccc: 2,
  fcc: 3,
  cco: 4,
  la: 5,
  implementation: 6,
  documentation: 7,
} as const;

export type TabPathSegment = keyof typeof pathMap;

/** derive previous tab path segment from pathMap, eg: { rules: 'settings', ... } */
export const prevTabMap = Object.entries(pathMap).reduce(
  (acc, [key, index], _, arr) => ({
    ...acc,
    // prettier-ignore
    ...(
      // special-case settings, which navigates up to .../controls/
      key === 'settings' ? { [key]: '..' } :
      // workaround: CCO & LA are disabled in MVP
      key === 'implementation' ? { [key]: 'cc' } :
      // otherwise, target the previous tab
      { [key]: arr[index - 1][0] }
    ),
  }),
  {} as Record<TabPathSegment, string>,
);

/** derive next tab path segment from pathMap, eg: { settings: 'rules', ... } */
export const nextTabMap = Object.entries(pathMap).reduce(
  (acc, [key, index], _, arr) => ({
    ...acc,
    // prettier-ignore
    ...(
      // special-case settings, which navigates up to .../controls/
      key === 'documentation' ? { [key]: '..' } :
      // workaround: CCO & LA are disabled in MVP
      key === 'fcc' ? { [key]: 'implementation' } :
      // otherwise, target the previous tab
      { [key]: arr[index + 1][0] }
    ),
  }),
  {} as Record<TabPathSegment, string>,
);

export type ControlDetailProps = WithQueryReturnProps<SiteControlEntityRecord>;

/**
 * Displays the tabbed interface and for a Control,
 * and constructs the context to be passed down to each tab.
 */
function ControlDetail(controlFormControls: ControlDetailProps) {
  const { toString } = useLocale();
  const navigate = useNavigate();
  const { actions, setActions, siteFramework } =
    useOutletContext<FrameworkOutletContext>();
  const { pathname } = useLocation();
  const { controlUuid, siteFrameworkUuid } = useParams();

  const isNew = controlUuid?.toLowerCase() == 'new';

  const tabSegment =
    pathname
      // the path segment immediately after the controlUuid...
      .split(`${controlUuid}/` as string)
      .at(-1)
      // ...dropping trailing path segments, defaulting to 'settings'
      ?.split('/')[0] ?? 'settings';
  const currentTab = pathMap[tabSegment as keyof typeof pathMap];

  log('%o', controlFormControls);

  useEffect(() => {
    const lastPathSegment = pathname.split(`${controlUuid}/`).at(-1);
    const targetBase = `/control-frameworks/${siteFrameworkUuid}/controls/${controlUuid}/`;

    setActions({
      ...actions,
      previous: () =>
        navigate(targetBase + prevTabMap[lastPathSegment as TabPathSegment]),
      next: () =>
        navigate(targetBase + nextTabMap[lastPathSegment as TabPathSegment]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const context = {
    controlFormControls,
    siteFramework,
  } satisfies ControlDetailContextType;

  /** type safe navigation factory */
  const createHandleNavigate = (slug: TabPathSegment) => () =>
    void navigate(slug);

  return (
    <Flex layerStyle="cardLike">
      <VStack gap={2}>
        <Heading size="xs" variant="primary">
          {isNew ? (
            <FormattedMessage
              id="control-framework.control.detail.create-new-control"
              defaultMessage="Create a new control"
            />
          ) : (
            toString(controlFormControls.value)
          )}
        </Heading>

        <Text fontSize="xs" color="muted">
          <FormattedMessage
            id="control-framework.control.detail.help-text-for-this-current-framework"
            defaultMessage="For this current control framework"
          />
        </Text>
      </VStack>

      <Tabs
        index={currentTab} // control via routing
        isLazy // only load the selected tab
        variant="line"
      >
        <TabList>
          <Tab onClick={createHandleNavigate('settings')}>
            <FormattedMessage
              id="control-framework.control.detail.tab.settings"
              defaultMessage="Settings"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('rules')}>
            <FormattedMessage
              id="control-framework.control.detail.tab.rules"
              defaultMessage="Applicability"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('ccc')}>
            <FormattedMessage
              id="control-framework.control.detail.tab.ccc"
              defaultMessage="CCC"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('fcc')}>
            <FormattedMessage
              id="control-framework.control.detail.tab.fcc"
              defaultMessage="FCC"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('cco')} isDisabled>
            <FormattedMessage
              id="control-framework.control.detail.tab.cco"
              defaultMessage="CCO"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('la')} isDisabled>
            <FormattedMessage
              id="control-framework.control.detail.tab.la"
              defaultMessage="LA"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('implementation')}>
            <FormattedMessage
              id="control-framework.control.detail.tab.implementation"
              defaultMessage="Adoption"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('documentation')}>
            <FormattedMessage
              id="control-framework.control.detail.tab.documentation"
              defaultMessage="Documentation"
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export const ControlFrameworkControlDetail = withQuery(() => {
  const { controlUuid } = useParams();
  return {
    action: SiteControlEntity.duck.actions.get({ id: controlUuid }),
  };
})(ControlDetail);
