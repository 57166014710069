import { Box, type BoxProps, type SystemStyleObject } from '@chakra-ui/react';

const resizerSx: SystemStyleObject = {
  '--gridArea': '1 / 2',

  display: 'inline-grid',
  gridTemplateColumns: 'min-content',
  width: 'full',

  _after: {
    gridArea: 'var(--gridArea)',
    content: `attr(data-value) ' '`,
    visibility: 'hidden',
    whiteSpace: 'pre-wrap',

    // passed styles should match the textarea inside
  },

  textarea: {
    gridArea: 'var(--gridArea)',
    height: 'auto',
    resize: 'none',
    overflow: 'hidden',
  },

  '&.debug': {
    _after: {
      visibility: 'inherit',
      color: 'red.500',
    },

    textarea: {
      bgColor: 'transparent',
    },
  },
};

const defaultAfterSx = {
  // these properties should match the textarea inside
  paddingX: 4,
  paddingY: 2,
  border: '1px solid transparent',
  lineHeight: 'var(--chakra-lineHeights-short)',
};

type InputResizerProps = BoxProps & {
  /** Should be the same value as provided to the child Textarea */
  value: string;
  /**
   * These styles should make the invisible text in rendered
   * `InputResizer::after` match the text rendered inside the actual textarea.
   *
   * Defaults to values suitable for a chakra Textarea.
   */
  afterSx?: SystemStyleObject;

  /** reveals the text rendered in `::after`, to aid in crafting the perfect afterSx */
  debug?: boolean;
};

/**
 * Causes the contained Textarea to expand to fit its content without rendering
 * scrollbars.
 *
 * ```tsx
 * <InputResizer value={value}>
 *   <Textarea value={value} />
 * </InputResizer>
 * ```
 *
 * **Tip: pass the `debug` flag to assist getting the afterSx right!**
 *
 * > The JavaScript sets a data-* attribute on the element equal to the value of
 * > the input. The input is set within a CSS grid, where that grid is a
 * > pseudo-element that uses that data-* attribute as its content. That content
 * > is what stretches the grid to the appropriate size based on the input
 * > value.
 *
 * @see https://css-tricks.com/auto-growing-inputs-textareas/#aa-other-ideas
 */
export const InputResizer = ({
  value,
  sx,
  afterSx,
  debug,
  ...props
}: InputResizerProps) => (
  <Box
    data-value={value}
    className={debug ? 'debug' : ''}
    sx={{
      ...resizerSx,
      _after: {
        ...(resizerSx?._after as SystemStyleObject),
        ...(afterSx ?? defaultAfterSx),
      },
      ...sx,
    }}
    {...props}
  />
);
