import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import type { Field } from '@burnsred/entity';
import { Form, Input } from '@burnsred/entity-form';
import Autocomplete from 'components/widgets/Autocomplete/Autocomplete';
import type { SiteFrameworkFilterEntityRecord } from 'entities/filter/SiteFrameworkFilter';
import type { EntityFormProps } from 'types';

type FrameworkFiltersProps = {
  name: string;
  filterRecord: SiteFrameworkFilterEntityRecord;
  onChange: EntityFormProps['onChange'];
  errors: EntityFormProps['errors'];
  FilterField: Field;
};

export const FrameworkFilters = ({
  name,
  filterRecord,
  onChange,
  FilterField,
  errors,
}: FrameworkFiltersProps) => {
  return (
    <Form
      name={name}
      field={FilterField}
      value={filterRecord}
      errors={errors}
      onChange={onChange}
      onSubmit={onChange}
    >
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing="5">
        <FormControl isRequired>
          <FormLabel>
            <FormattedMessage
              id="control-frameworks.filters.label.risk"
              defaultMessage="Level 3 Risk"
            />
          </FormLabel>
          <Input
            name="risk"
            componentProps={{ onChange }}
            component={Autocomplete}
            loadOptionsFromAPI
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>
            <FormattedMessage
              id="control-frameworks.filters.label.asset"
              defaultMessage="Asset"
            />
          </FormLabel>
          <Input
            name="asset"
            component={Autocomplete}
            componentProps={{ onChange }}
            loadOptionsFromAPI
            filterParams={{
              is_location: 'false',
              is_active: 'true',
              level: '1',
            }}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>
            <FormattedMessage
              id="control-frameworks.filters.label.operation"
              defaultMessage="Operation"
            />
          </FormLabel>
          <Input
            name="operation"
            component={Autocomplete}
            loadOptionsFromAPI
            componentProps={{
              disabled: !filterRecord?.get('asset'),
            }}
            filterParams={{
              is_location: 'false',
              is_active: 'true',
              level: '2',
              parent: filterRecord?.get('asset')?.get('uuid') || undefined,
              page_size: '100',
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="control-frameworks.filters.label.timezone"
              defaultMessage="Event Phase"
            />
          </FormLabel>
          <Input
            name="timezone"
            component={Autocomplete}
            loadOptionsFromAPI
            componentProps={{ onChange }}
          />
        </FormControl>
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="5">
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="control-frameworks.filters.label.damaging-energy-mechanism"
              defaultMessage="Damaging energy mechanism"
            />
          </FormLabel>
          <Input
            name="damage_energy_mechanism"
            component={Autocomplete}
            loadOptionsFromAPI
            filterParams={{
              damage_energy__risk:
                filterRecord?.get('risk')?.get('uuid') || undefined,
              page_size: '100',
            }}
            componentProps={{ onChange }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="control-frameworks.filters.label.scenario"
              defaultMessage="Scenario"
            />
          </FormLabel>
          <Input
            name="scenario"
            component={Autocomplete}
            componentProps={{ onChange }}
            filterParams={{
              damage_energy_mechanism__damage_energy__risk:
                filterRecord?.get('risk')?.get('uuid') || undefined,
              damage_energy_mechanism:
                filterRecord?.get('damage_energy_mechanism')?.get('uuid') ||
                undefined,
              page_size: '200',
            }}
            loadOptionsFromAPI
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="control-frameworks.filters.label.control"
              defaultMessage="Site control"
            />
          </FormLabel>
          <Input
            name="control"
            component={Autocomplete}
            loadOptionsFromAPI
            filterParams={{ page_size: '200' }}
          />
        </FormControl>
      </SimpleGrid>
    </Form>
  );
};
