import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Paginator, SimpleEntityList } from 'components';
import { FieldObservationReport } from 'entities';

import { cardSx } from './ActivityList.styles';
import {
  ActivityFilters,
  ActivityTable,
  ActivityTableRow,
  useActivityListControls,
} from './components';
import { ActivityTableLegend } from './components/ActivityTableLegend';

export function ActivityList() {
  const activityListControls = useActivityListControls();
  const { params, paginatorProps } = activityListControls;

  return (
    <Flex direction="column" gap={6}>
      <Heading size="lg">
        <FormattedMessage id="activity-list.title" defaultMessage="Activity" />
      </Heading>

      <ActivityFilters {...{ ...activityListControls }} />

      <Card variant="outline" sx={cardSx}>
        <CardHeader>
          <Heading as="h3" size="xs">
            <FormattedMessage
              id="activity-list.latest-activity"
              defaultMessage="Latest activity"
            />
            {paginatorProps.totalItems
              ? ` (${paginatorProps.totalItems})`
              : null}
          </Heading>
        </CardHeader>

        <CardBody gap={4}>
          <SimpleEntityList
            Entity={FieldObservationReport}
            RecordComponent={ActivityTableRow}
            ListComponent={ActivityTable}
            params={params}
          />
        </CardBody>

        <CardFooter justifyContent="space-between" alignItems="center">
          <Paginator displayAlways {...paginatorProps} />
          <ActivityTableLegend />
        </CardFooter>
      </Card>
    </Flex>
  );
}
