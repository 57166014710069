import {
  Input as ChakraInput,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
} from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Loading } from '@burnsred/ui-chakra';
import { TimeZoneSelector, VStack } from 'components';
import { Fieldset } from 'forms/fields';
import { useLocale } from 'locales/useLocale.tsx';
import { createLogger } from 'util/createLogger';

import { inputSx } from './GlobalFrameworkControlSettings.styles';
import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

const log = createLogger('ControlSettings');

export const GlobalFrameworkControlSettings = ({
  controlFormControls,
}: GlobalControlDetailContextType) => {
  const control = controlFormControls?.value;
  log('%o', { control });

  const { toString } = useLocale();

  if (!control) return <Loading />;

  return (
    <VStack>
      <Fieldset heading={null}>
        <GridItem colSpan={{ base: 12, md: 6 }}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.control-title"
                defaultMessage="Control title"
              />
            </FormLabel>
            <ChakraInput
              isReadOnly
              defaultValue={toString(control)}
              size="sm"
              sx={inputSx}
            />
          </FormControl>
        </GridItem>
        {/* Control Designation Field */}
        <GridItem colSpan={{ base: 12, md: 3 }}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.tab.settings.label.control-designation"
                defaultMessage="Control designation"
              />
            </FormLabel>
            <ChakraInput
              isReadOnly
              defaultValue={toString(control?.get('control_designation'))}
              size="sm"
              sx={inputSx}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 3 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.fel-link"
                defaultMessage="FEL link #"
              />
            </FormLabel>
            <ChakraInput
              isReadOnly
              defaultValue={toString(control, 'fel_link_number')}
              size="sm"
              sx={inputSx}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={{ base: 12, md: 9 }}>
          <FormControl isRequired>
            <FormLabel>
              <FormattedMessage
                id="global-framework.control.label.timezones"
                defaultMessage="Control event phases"
              />
            </FormLabel>

            <TimeZoneSelector timezones={control.get('timezones')} isDisabled />

            <FormHelperText>
              <FormattedMessage
                id="global-framework.control.help-text.timezone"
                defaultMessage="Select the control event phase(s) where the control is present."
              />
            </FormHelperText>
          </FormControl>
        </GridItem>
      </Fieldset>
    </VStack>
  );
};
