import { Divider, Flex, Heading } from '@chakra-ui/react';
import { type List } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { EntriesFound, Paginator, VStack } from 'components';
import { Stats } from 'components/Stats/Stats';
import { type SiteFrameworkReportEntityRecord } from 'entities/reporting/SiteFrameworkReport';
import { FrameworksList } from 'screens/control-frameworks/components/FrameworksList';

import { DashboardBottomFilters } from './DashboardBottomFilterForm/DashboardBottomFilterForm';
import { DashboardTopFilters } from './DashboardTopFilterForm/DashboardTopFilterForm';
import { useRiskOwnerControls } from './RiskOwner.hooks';

export function RiskOwnerDashboard() {
  const {
    name,
    filterRecord,
    onChange,
    FilterField,
    errors,
    stats,
    params,
    paginatorProps,
  } = useRiskOwnerControls();

  if (!filterRecord?.get('site_association__risk_owner')) return null;

  return (
    <Flex direction="column" gap={8}>
      <Heading size="lg">
        <FormattedMessage
          id="dashboard.control-frameworks.stats.heading"
          defaultMessage="My control frameworks performance"
        />
      </Heading>

      <DashboardTopFilters
        name={name}
        filterRecord={filterRecord}
        onChange={onChange}
        FilterField={FilterField}
        errors={errors}
      />

      <Stats
        stats={stats as List<SiteFrameworkReportEntityRecord>}
        filterRecord={filterRecord}
        risk_owner={filterRecord?.get('site_association__risk_owner')}
      />

      <Divider />

      <Heading as="h3" size="sm">
        <FormattedMessage
          id="dashboard.control-frameworks.list.heading"
          defaultMessage="My control frameworks"
        />
      </Heading>

      <DashboardBottomFilters
        name={name}
        filterRecord={filterRecord}
        onChange={onChange}
        FilterField={FilterField}
        errors={errors}
      />

      <VStack gap={4}>
        <EntriesFound count={paginatorProps.totalItems} />

        <FrameworksList
          filterRecord={filterRecord}
          params={params}
          refreshList={0}
          showStats={true}
          risk_owner={filterRecord?.get('site_association__risk_owner')}
        />
      </VStack>

      <Paginator displayAlways={false} {...paginatorProps} />
    </Flex>
  );
}
