import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
} from '@chakra-ui/react';
import { fromJS } from 'immutable';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, Input } from '@burnsred/entity-form';
import { VStack } from 'components';
import { Autocomplete, WidgetRadio } from 'components/widgets';
import { PeriodSelect } from 'components/widgets/PeriodSelect/PeriodSelect';
import { PersonPicker } from 'components/widgets/PersonPicker';
import { PERIOD_OPTIONS } from 'entities/filter/periodOptions';

import {
  type UseActivityListControlsReturn,
  observationTypeOptions,
} from './ActivityFilters.hooks';
import {
  activityTypeOptionsSx,
  activityTypeRowSx,
  activityTypeSx,
  maxContentSx,
} from './ActivityFilters.styles';

export const ActivityFilters = ({
  name,
  filterRecord,
  params,
  onChangeObservationType,
  onChange,
  FilterField,
  errors,
}: UseActivityListControlsReturn) => {
  const { formatMessage } = useIntl();

  // REVIEW do observationTypeOptions need resetting when hidden?
  const observationTypes = filterRecord.get('observation_types');
  const isCCCSelected = observationTypes.includes('CriticalControlCheck');
  const isFCCSelected = observationTypes.includes('ControlConfirmation');

  return (
    <Form
      name={name}
      field={FilterField}
      value={filterRecord}
      errors={errors}
      onChange={onChange}
      onSubmit={onChange}
    >
      <Grid gridTemplateColumns="repeat(4, 1fr)" gap={4}>
        {/* Row 1 */}
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="activity-list.filters.risk"
              defaultMessage="Level 3 risk"
            />
          </FormLabel>
          <Input
            name="risk"
            component={Autocomplete}
            componentProps={{ onChange }}
            loadOptionsFromAPI
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="activity-list.filters.asset"
              defaultMessage="Asset"
            />
          </FormLabel>
          <Input
            name="asset"
            component={Autocomplete}
            componentProps={{ onChange }}
            loadOptionsFromAPI
            filterParams={{
              is_location: 'false',
              is_active: 'true',
              level: '1',
              self_or_child_has_risk: params.get(
                'CubeObservation___framework__risk',
              ),
              // TODO options filtering: asset
              has_site_framework: params.get('CubeObservation___framework'),
              has_site_control: params.get('CubeObservation___site_control'),
              has_site_framework_owned_by_site_association__risk_owner:
                params.get('CubeObservation___site_association__risk_owner'),
              has_site_control_owned_by_site_status__control__control_owner:
                params.get('CubeObservation___site_control_owner'),
              child_has_site_framework: params.get(
                'CubeObservation___framework',
              ),
              child_has_site_control: params.get(
                'CubeObservation___site_control',
              ),
              child_has_site_framework_owned_by_site_association__risk_owner:
                params.get('CubeObservation___site_association__risk_owner'),
              child_has_site_control_owned_by_site_status__control__control_owner:
                params.get('CubeObservation___site_control_owner'),
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="activity-list.filters.operation"
              defaultMessage="Operation"
            />
          </FormLabel>
          <Input
            name="operation"
            component={Autocomplete}
            componentProps={{
              onChange,
              disabled: !filterRecord.get('asset'),
            }}
            loadOptionsFromAPI={!!filterRecord.get('asset') || undefined}
            filterParams={{
              is_location: 'false',
              is_active: 'true',
              level: '2',
              parent: params.get('grc_organisational_unit__descendants'),
              has_risk: params.get('CubeObservation___framework__risk'),
              // TODO options filtering: operation
              has_site_framework: params.get('CubeObservation___framework'),
              has_site_control: params.get('CubeObservation___site_control'),
              has_site_framework_owned_by_site_association__risk_owner:
                params.get('CubeObservation___site_association__risk_owner'),
              has_site_control_owned_by_site_status__control__control_owner:
                params.get('CubeObservation___site_control_owner'),
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="activity-list.filters.period"
              defaultMessage="Show period"
            />
          </FormLabel>

          <Input
            name="observation_date__gte"
            component={PeriodSelect}
            componentProps={{
              onChange,
              options: PERIOD_OPTIONS,
            }}
          />
        </FormControl>

        {/* Row 2 */}
        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="activity-list.filters.control-framework"
                defaultMessage="Control framework"
              />
            </FormLabel>

            <Input
              name="site_framework"
              component={Autocomplete}
              componentProps={{ onChange }}
              loadOptionsFromAPI
              filterParams={{
                page_size: '200',
                risk: params?.get('CubeObservation___framework__risk'),
              }}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={2}>
          <FormControl>
            <FormLabel>
              <FormattedMessage
                id="activity-list.filters.site-control"
                defaultMessage="Site control"
              />
            </FormLabel>

            <Input
              name="site_control"
              component={Autocomplete}
              componentProps={{
                onChange,
                // avoid showing indistinguishable same-name site_control options from different frameworks
                isDisabled:
                  !filterRecord.get('site_control') &&
                  !filterRecord.get('site_framework'),
              }}
              loadOptionsFromAPI={
                !!filterRecord.get('site_framework') || undefined
              }
              filterParams={{
                rule_site_framework: params?.get('CubeObservation___framework'),
              }}
            />
          </FormControl>
        </GridItem>

        {/* Row 3 */}
        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="activity-list.filters.risk-owner"
              defaultMessage="Risk owner"
            />
          </FormLabel>

          <Input
            name="site_association__risk_owner"
            component={PersonPicker}
            componentProps={{ onChange }}
            loadOptionsFromAPI
            filterParams={{
              is_site_association__risk_owner: 'true',
              owns_site_framework__risk: params?.get(
                'CubeObservation___framework__risk',
              ),
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="activity-list.filters.control-owner"
              defaultMessage="Control owner"
            />
          </FormLabel>

          <Input
            name="site_control_owner"
            component={PersonPicker}
            componentProps={{ onChange }}
            loadOptionsFromAPI
            filterParams={{
              is_site_control_owner: 'true',
              // TODO options filtering: site_control_owner
              is_applied_to_risk: params?.get(
                'CubeObservation___framework__risk',
              ),
              is_active_at_asset: params?.get(
                'grc_organisational_unit__descendants',
              ),
              is_active_at_operation: params?.get('grc_organisational_unit'),
              site_framework: params?.get('CubeObservation___framework__risk'),
              is_owner_of_site_control: params?.get(
                'CubeObservation___site_control',
              ),
            }}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <FormattedMessage
              id="activity-list.filters.lead-observer"
              defaultMessage="Lead observer"
            />
          </FormLabel>

          <Input
            name="lead_observer"
            component={PersonPicker}
            componentProps={{ onChange }}
            loadOptionsFromAPI
            filterParams={{
              bhp_is_active: 'true',
            }}
          />
        </FormControl>

        <GridItem />

        {/* Row 4 */}
        <GridItem colSpan={4} sx={activityTypeRowSx}>
          <FormControl sx={activityTypeSx}>
            <FormLabel>
              <FormattedMessage
                id="activity-list.filters.activity-type"
                defaultMessage="Activity type"
              />
            </FormLabel>

            <HStack gap={6}>
              <CheckboxGroup
                onChange={onChangeObservationType}
                value={filterRecord.get('observation_types')}
              >
                <HStack gap={6}>
                  {observationTypeOptions?.map(({ value, label }) => (
                    <Checkbox
                      key={value}
                      name="observation_type"
                      value={value}
                      // TODO disable this Checkbox if the other is _unchecked_
                    >
                      {formatMessage(label)}
                    </Checkbox>
                  ))}
                </HStack>
              </CheckboxGroup>
            </HStack>
          </FormControl>

          {isCCCSelected && (
            <FormControl sx={activityTypeOptionsSx}>
              <FormLabel>
                <FormattedMessage
                  id="activity-list.filters.ccc-options"
                  defaultMessage="Critical Control Check"
                />
              </FormLabel>

              <VStack gap={2}>
                <HStack sx={maxContentSx}>
                  <FormattedMessage
                    id="activity-list.filters.ccc-has-fixed"
                    defaultMessage="Has fixed on spot?"
                  />
                  <Input
                    name="CriticalControlCheck__has_fixed"
                    component={WidgetRadio}
                    componentProps={{
                      onChange,
                      ...sharedRadioProps,
                    }}
                  />
                </HStack>

                <HStack sx={maxContentSx}>
                  <FormattedMessage
                    id="activity-list.filters.ccc-has-interrupt"
                    defaultMessage="Has interruption?"
                  />
                  <Input
                    name="CriticalControlCheck__has_interruption"
                    component={WidgetRadio}
                    componentProps={{
                      onChange,
                      ...sharedRadioProps,
                    }}
                  />
                </HStack>
              </VStack>
            </FormControl>
          )}

          {isFCCSelected && (
            <FormControl sx={activityTypeOptionsSx}>
              <FormLabel>
                <FormattedMessage
                  id="activity-list.filters.fcc-options"
                  defaultMessage="Field Control Confirmation"
                />
              </FormLabel>

              <HStack sx={maxContentSx}>
                <FormattedMessage
                  id="activity-list.filters.fcc-has-actions"
                  defaultMessage="Has actions?"
                />
                <Input
                  name="are_follow_up_actions_required"
                  component={WidgetRadio}
                  componentProps={{
                    onChange,
                    ...sharedRadioProps,
                  }}
                />
              </HStack>
            </FormControl>
          )}
        </GridItem>
      </Grid>
    </Form>
  );
};

const sharedRadioProps = {
  getOptionLabel: ({ label }: { label: string }) => (
    <FormattedMessage
      id={`activity-list.filters.${label.toLowerCase()}`}
      defaultMessage={label}
    />
  ),
  transformValueFrom: (newValue: string) =>
    newValue == 'null' ? null : newValue == 'true',
  options: fromJS([
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
    { value: null, label: 'Both' },
  ]),
  gap: 4,
};
