import type { SystemStyleObject } from '@chakra-ui/react';

const cellStyles: SystemStyleObject = {
  verticalAlign: 'top',
  paddingX: 2,
  borderColor: 'border',
  fontSize: 'xs',

  '&:first-of-type': {
    paddingLeft: 0,
  },
  '&:last-of-type': {
    paddingRight: 0,
    textAlign: 'right',
  },
  '&.center': {
    paddingX: 0,
    textAlign: 'center',
  },
};

export const tableSx: SystemStyleObject = {
  '&.chakra-table': {},

  tr: {
    '&.isInterruption': {
      color: 'error',
    },
  },

  th: {
    ...cellStyles,
    paddingRight: 0,
    verticalAlign: 'bottom',
    fontWeight: 'normal',
    textTransform: 'inherit',
    color: 'muted',
    borderBottom: 'none', // Remove bottom border of header cells
    paddingY: 2,
  },
  td: {
    ...cellStyles,
  },
};

export const iconSx: SystemStyleObject = {
  boxSize: 4,
  // HACK prevent icons from distorting row spacing
  marginBottom: '-5px',

  _hover: { cursor: 'help' },
};

export const openLinkSx: SystemStyleObject = {
  fontWeight: 'normal',
};
