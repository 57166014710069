import { List, Map } from 'immutable';
import { useCallback, useMemo } from 'react';

import type { EquipmentEntityRecord } from 'entities/api/Equipment';
import type { OperatingContextEntityRecord } from 'entities/api/OperatingContext';
import { useLocale } from 'locales/useLocale';
import type { TypedMap } from 'types';
import { createLogger } from 'util/createLogger';

import type { MandatoryAssessTableProps } from './MandatoryAssessTable';

const log = createLogger('MandatoryAssessTable');

type UseMandatoryAssessTableProps = MandatoryAssessTableProps;

export const useMandatoryAssessTable = ({
  applicableRules,
  scenarios,
  filterRecord,
  equipmentLevel1,
  equipmentLevel2,
  operatingContexts,
}: UseMandatoryAssessTableProps) => {
  const { toString } = useLocale();

  /**
   * An aggregated, de-duped list of equipment UUIDs from all equipment
   * predicates for this control. Each UUID might be L1 or L2.
   */
  const equipmentUuids = applicableRules
    ?.map((rule) =>
      rule
        ?.get('predicatesByModelName')
        ?.get('equipment')
        ?.sort((a, b) => toString(a).localeCompare(toString(b)))
        ?.map((rec) => rec.get('object_id')),
    )
    .flatten()
    .toSet()
    .toList() as List<string>;

  /** ALL equipment from L1 & L2 filters, used to establish when a row is highlighted */
  const equipmentsFiltered = filterRecord
    ?.get('equipment_l1')
    ?.merge(filterRecord.get('equipment_l2'));

  /** Operating contexts, used to establish when a column is highlighted */
  const operatingContextsFiltered = filterRecord?.get('operating_context');

  const scenarioTitles = scenarios
    .map((el) => toString(el))
    .toSet() // de-duplicate
    .toList();

  /** find rules matching the given equipment && operatingcontext */
  const getRulesForCell = useCallback(
    (equipment: EquipmentEntityRecord, opCtx: OperatingContextEntityRecord) =>
      applicableRules.filter((rule) => {
        const applicableRulesEquipmentUuids = rule
          .get('predicatesByModelName')
          ?.get('equipment')
          ?.map((predicate) => predicate.get('object_id'))
          .toSet();

        const opCtxUuids = rule
          .get('predicatesByModelName')
          ?.get('operatingcontext')
          ?.map((predicate) => predicate.get('object_id'));

        return (
          applicableRulesEquipmentUuids?.includes(equipment.get('uuid')) &&
          opCtxUuids?.includes(opCtx.get('uuid'))
        );
      }),
    [applicableRules],
  );

  type TableRow = TypedMap<
    {
      equipmentL1: EquipmentEntityRecord;
      equipmentL2: EquipmentEntityRecord;
      equipmentL1ChildCount: number;
      childCount: number;
      childIndex: number;
    } & Record<string, ReturnType<typeof getRulesForCell>>
  >;

  const tableData = useMemo(() => {
    /** Can there be a situation where an L2 is displayed without a parent? */
    const L1Placeholder = Map({ uuid: 'none', placeholder: true });

    const l2Rows = (equipmentLevel2?.map((equipmentL2Row) => {
      const equipmentL1 = equipmentLevel1?.find(
        (rec) => rec.get('uuid') == equipmentL2Row?.get('parent'),
      );

      const children = equipmentLevel2.filter(
        (l2) => l2.get('parent') == equipmentL2Row.get('parent'),
      );

      const row = Map<string, unknown>({
        equipmentL1: equipmentL1 ?? L1Placeholder,
        equipmentL2: equipmentL2Row,
        childCount: children.count(),
      }).withMutations((rec) => {
        operatingContexts.forEach((opCtx) =>
          rec.set(toString(opCtx), getRulesForCell(equipmentL2Row, opCtx)),
        );
      });
      return row;
    }) ?? List([])) as unknown as List<TableRow>;

    let childIndex = 0;

    return l2Rows.map((row, i, list) => {
      const currentParent = row.get('equipmentL1');
      const prevParent = list.get(i - 1)?.get('equipmentL1');

      if (!currentParent.equals(prevParent)) childIndex = 0;
      else childIndex++;

      return row.withMutations((rec) => {
        rec.set('childIndex', childIndex);
      });
    });
  }, [
    equipmentLevel1,
    equipmentLevel2,
    getRulesForCell,
    operatingContexts,
    toString,
  ]);

  log('%o', {
    tableData,
    applicableRules,
    equipmentUuids,
    equipmentLevel1,
    equipmentLevel2,
    operatingContexts,
    equipmentsFiltered,
    scenarioTitles,
  });

  return {
    tableData,
    equipmentsFiltered,
    operatingContextsFiltered,
    scenarioTitles,
  };
};
