import { Grid } from '@chakra-ui/react';
import { type List, type Map } from 'immutable';
import type { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';

import { PerformanceCard } from 'components';
import { performanceCardGridSx } from 'components/PerformanceCard/PerformanceCard.style';
import type { GrcOrganisationalUnitEntityRecord } from 'entities/api/Person/GrcOrganisationalUnit';
import type { PersonEntityRecord } from 'entities/api/Person/Person';
import type { RiskEntityRecord } from 'entities/api/Risk';
import type { ActivityFilterEntityFields } from 'entities/filter/ActivityFilter';
import { type SiteControlReportEntityRecord } from 'entities/reporting/SiteControlReport';
import { type SiteFrameworkReportEntityRecord } from 'entities/reporting/SiteFrameworkReport';
import {
  activityListFiltersReset,
  useActivityListControls,
} from 'screens/activities/components';

type StatsProps = {
  stats: List<SiteFrameworkReportEntityRecord | SiteControlReportEntityRecord>;
  filterRecord: Map<string, unknown>;
  risk_owner?: PersonEntityRecord;
  site_control_owner?: PersonEntityRecord;
};

export const Stats = ({
  stats,
  filterRecord,
  risk_owner,
  site_control_owner,
}: StatsProps) => {
  const { createHandleNavigateToActivityList } = useActivityListControls();
  const report = stats?.get(0);

  const commonActivityListFilters = {
    ...activityListFiltersReset,
    observation_date__gte: filterRecord?.get('with_statistics__gte') as
      | Moment
      | undefined,
    risk: filterRecord?.get('risk') as RiskEntityRecord,
    asset: filterRecord?.get(
      'descendants',
    ) as GrcOrganisationalUnitEntityRecord,
    operation: filterRecord?.get(
      'grc_organisational_unit',
    ) as GrcOrganisationalUnitEntityRecord,
    site_association__risk_owner: risk_owner,
    site_control_owner,
  } satisfies Partial<ActivityFilterEntityFields>;

  return (
    <Grid sx={performanceCardGridSx}>
      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.ccc"
            defaultMessage="Critical control check"
          />
        }
        stats={[
          {
            metric: report?.get('ccc_total') ?? 0,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.ccc"
                defaultMessage="Critical control check"
              />
            ),
            change:
              (report?.get('ccc_total') ?? 0) -
              (report?.get('ccc_total_prev') ?? 0),
          },
        ]}
        onClick={createHandleNavigateToActivityList({
          ...commonActivityListFilters,
          observation_types: ['CriticalControlCheck'],
        })}
      />

      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.fcc"
            defaultMessage="Field control confirmation"
          />
        }
        stats={[
          {
            metric: report?.get('cc_total') ?? 0,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.fcc"
                defaultMessage="Field control confirmation"
              />
            ),
            change:
              (report?.get('cc_total') ?? 0) -
              (report?.get('cc_total_prev') ?? 0),
          },
        ]}
        onClick={createHandleNavigateToActivityList({
          ...commonActivityListFilters,
          observation_types: ['ControlConfirmation'],
        })}
      />

      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.interruptions"
            defaultMessage="Interruptions"
          />
        }
        stats={[
          {
            metric: [
              {
                metric: report?.get('ccc_interrupt_total') ?? 0,
                label: (
                  <FormattedMessage
                    id="ControlCardWithStats.ccc.interrupts"
                    defaultMessage="Interrupts"
                  />
                ),
                onClick: createHandleNavigateToActivityList({
                  ...commonActivityListFilters,
                  observation_types: ['CriticalControlCheck'],
                  CriticalControlCheck__has_interruption: true,
                }),
              },
              {
                metric: report?.get('ccc_fix_total') ?? 0,
                label: (
                  <FormattedMessage
                    id="ControlCardWithStats.ccc.fix"
                    defaultMessage="Fix"
                  />
                ),
                onClick: createHandleNavigateToActivityList({
                  ...commonActivityListFilters,
                  observation_types: ['CriticalControlCheck'],
                  CriticalControlCheck__has_fixed: true,
                }),
              },
            ],
            label: (
              <FormattedMessage
                id="dashboard.stats.label.interrupts-fix"
                defaultMessage="Interrupts/Fix"
              />
            ),
          },
          {
            metric: report?.get('cc_with_actions_total') ?? 0,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.action"
                defaultMessage="Actions"
              />
            ),
            onClick: createHandleNavigateToActivityList({
              ...commonActivityListFilters,
              observation_types: ['ControlConfirmation'],
              are_follow_up_actions_required: true,
            }),
          },
        ]}
      />
      <PerformanceCard
        heading={
          <FormattedMessage
            id="dashboard.stats.implementation"
            defaultMessage="Implementation"
          />
        }
        stats={[
          {
            metric: `${report?.get('site_framework_active_total') ?? 0} / ${report?.get('site_framework_total') ?? 0}`,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.site-frameworks"
                defaultMessage="Site Frameworks"
              />
            ),
          },
          {
            metric: `${report?.get('control_implementations_active_total') ?? 0} / ${report?.get('control_implementations_total') ?? 0}`,
            label: (
              <FormattedMessage
                id="dashboard.stats.label.controls"
                defaultMessage="Controls"
              />
            ),
          },
        ]}
        // Implementation card deliberately not linked
      />
    </Grid>
  );
};
