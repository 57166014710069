import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types.ts';

import EquipmentEntity, { type EquipmentEntityRecord } from './Equipment';
import I18nTextEntity, {
  type I18nTextEntityRecord,
  toString,
} from './i18n/I18nText.ts';
import OperatingContextEntity, {
  type OperatingContextEntityRecord,
} from './OperatingContext';
import RiskEntity, { type RiskEntityRecord } from './Risk';
import ScenarioModifierCategoryEntity, {
  type ScenarioModifierCategoryEntityRecord,
} from './ScenarioModifierCategory';
import WorkLocationEntity, {
  type WorkLocationEntityRecord,
} from './WorkLocation.ts';

class GlobalFrameworkEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/global_framework/',
  };

  static fields: EntityFields<GlobalFrameworkEntityFields> = {
    uuid: new Fields.IdField(),
    operating_contexts: new Fields.EntityField({
      entity: OperatingContextEntity,
      many: true,
    }),

    equipment: new Fields.EntityField({
      entity: EquipmentEntity,
      many: true,
    }),

    scenario_modifier_categories: new Fields.EntityField({
      entity: ScenarioModifierCategoryEntity,
      many: true,
    }),

    work_locations: new Fields.EntityField({
      entity: WorkLocationEntity,
      many: true,
    }),

    risk: new Fields.EntityField({
      entity: RiskEntity,
    }),

    // @Fixme: probably should be user entity
    owner: new Fields.CharField({
      blank: true,
      maxLength: 255,
    }),

    count_controls_existing: new Fields.IntegerField(),
    count_controls_implemented: new Fields.IntegerField(),
    count_control_frameworks: new Fields.IntegerField(),
    equipment_field_label: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),
  };
  static toString = toString<GlobalFrameworkEntityRecord>;
}

export type GlobalFrameworkEntityFields = {
  uuid: string;
  operating_contexts: List<OperatingContextEntityRecord>;
  equipment: List<EquipmentEntityRecord>;
  scenario_modifier_categories: List<ScenarioModifierCategoryEntityRecord>;
  work_locations: List<WorkLocationEntityRecord>;
  risk: RiskEntityRecord;
  owner: string;
  count_controls_existing: number;
  count_controls_implemented: number;
  count_control_frameworks: number;
  equipment_field_label: I18nTextEntityRecord;
};

export type GlobalFrameworkEntityRecord =
  EntityRecord<GlobalFrameworkEntityFields>;

GlobalFrameworkEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: GlobalFrameworkEntity,
  name: 'GlobalFramework',
});

export default GlobalFrameworkEntity;
