import type { SystemStyleObject } from '@chakra-ui/react';

export const gridCellSx: SystemStyleObject = {
  userSelect: 'none', // prevent unsightly selection of truncated text

  _hover: {
    cursor: 'pointer',
  },
};

export const cellSx: SystemStyleObject = {
  width: 'full',
  minHeight: 6,
  padding: 1.5,
  rounded: 'base',
  fontSize: 'xs',
  color: 'white',

  '.active &': {
    backgroundColor: 'primary',
  },

  _hover: {
    backgroundColor: 'primary',
  },

  svg: {
    marginLeft: 'auto',
  },
};

export const emptyGridCellSx: SystemStyleObject = {
  ...cellSx,
  backgroundColor: 'brandBlueGrey',

  _hover: { bgColor: 'none' },
};
