import { type Settings } from 'settings';

/** qa settings overrides */
export const settings: Partial<Settings> = {
  BASE_API_ORIGIN: 'https://qa.bhpb.burnsred.com.au',
  CSRF_COOKIE_NAME: 'bhpqacsrftoken',

  FLEX_URL: 'https://qa.bhpb.burnsred.com.au/apps/flex/#',
};

export default settings;
