import { type Settings } from 'settings';

/** test settings overrides */
export const settings: Partial<Settings> = {
  BASE_API_ORIGIN: 'https://test.bhpb.burnsred.com.au',
  CSRF_COOKIE_NAME: 'bhptestcsrftoken',

  FLEX_URL: 'https://test.bhpb.burnsred.com.au/apps/flex/#',
};

export default settings;
