import { radioAnatomy as parts } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

/**
 * Radio is a MultiPart Component
 *
 * @see https://v2.chakra-ui.com/docs/components/radio/usage
 * @see https://github.com/chakra-ui/chakra-ui/tree/v2/packages/components/src/radio
 * @see https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/radio.ts
 */
export const radioTheme: ComponentStyleConfig = defineMultiStyleConfig({
  baseStyle: {
    control: {
      borderColor: 'gray.500',

      _checked: {
        background: 'transparent',
        borderColor: 'primary',

        _before: {
          boxSize: '60%',
          background: 'primary',
        },

        _hover: {
          background: 'primary',
          borderColor: 'primary',
          _before: { background: 'white' },
        },
      },

      _disabled: {
        outline: '1px solid var(--chakra-colors-gray-400)',

        _checked: {
          _before: { background: 'gray.400' },
        },
      },
    },
  },

  sizes: {
    md: {
      control: {
        boxSize: 5,
        borderWidth: '2.5px',
      },
    },
  },

  // variants: {},

  defaultProps: {
    // variant: 'cube',
    size: 'md',
    // colorScheme: 'orange',
  },
});
