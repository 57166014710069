import type { SystemStyleObject } from '@chakra-ui/react';

export const activityTypeRowSx: SystemStyleObject = {
  display: 'flex',
  flexDirection: 'row',
  gap: 6,
};

export const activityTypeSx: SystemStyleObject = {
  width: 'min-content',
};

export const activityTypeOptionsSx: SystemStyleObject = {
  width: 'auto',
  paddingLeft: 6,
  borderLeft: '1px',
  borderColor: 'border',
};

export const maxContentSx: SystemStyleObject = {
  minWidth: 'max-content',
  justifyContent: 'space-between',
};
