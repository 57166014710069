import { Map } from 'immutable';
import type { Moment } from 'moment';

import { Entity, Fields } from '@burnsred/entity';
import ScreenDuck from '@burnsred/entity-duck-namespace-screen';
import { BhpPerson, SiteControl } from 'entities/api';
import GrcOrganisationalUnitEntity, {
  type GrcOrganisationalUnitEntityRecord,
} from 'entities/api/Person/GrcOrganisationalUnit';
import { type PersonEntityRecord } from 'entities/api/Person/Person';
import RiskEntity, { type RiskEntityRecord } from 'entities/api/Risk';
import type { SiteControlEntityRecord } from 'entities/api/SiteControl';
import SiteFrameworkSlimEntity, {
  type SiteFrameworkSlimEntityRecord,
} from 'entities/api/SiteFrameworkSlim';
import {
  type ObservationType,
  observationTypes,
} from 'entities/reporting/FieldObservationReport';
import {
  type EntityFields,
  type EntityRecord,
  type FormChangeEvent,
} from 'types';

import { LAST_SEVEN_DAYS } from './periodOptions';

class ActivityFilterEntity extends Entity {
  static fields: EntityFields<ActivityFilterEntityFields> = {
    risk: new Fields.EntityField({
      entity: RiskEntity,
      blank: true,
    }),
    observation_types: new Fields.CharField({
      default: observationTypes,
      many: true,
    }),
    asset: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    operation: new Fields.EntityField({
      entity: GrcOrganisationalUnitEntity,
      blank: true,
    }),
    observation_date__gte: new Fields.DateTimeField({
      blank: true,
      default: LAST_SEVEN_DAYS.value,
    }),
    lead_observer: new Fields.EntityField({
      entity: BhpPerson,
      blank: true,
    }),
    site_framework: new Fields.EntityField({
      entity: SiteFrameworkSlimEntity,
      blank: true,
    }),
    site_association__risk_owner: new Fields.EntityField({
      entity: BhpPerson,
      blank: true,
    }),

    site_control: new Fields.EntityField({
      entity: SiteControl,
      blank: true,
    }),
    site_control_owner: new Fields.EntityField({
      entity: BhpPerson,
      blank: true,
    }),

    CriticalControlCheck__has_fixed: new Fields.BooleanField({ null: true }),
    CriticalControlCheck__has_interruption: new Fields.BooleanField({
      null: true,
    }),
    are_follow_up_actions_required: new Fields.BooleanField({ null: true }),

    page_size: new Fields.CharField({
      default: '100',
      blank: true,
    }),
    page: new Fields.CharField({
      default: '1',
      blank: true,
    }),
  };

  static asMappedParams(record: ActivityFilterEntityRecord) {
    const params = {
      is_draft: 'false',

      observation_types: record.get('observation_types').join(','),
      CubeObservation___framework__risk: record?.get('risk')?.get('uuid') || '',

      grc_organisational_unit__descendants:
        record?.get('asset')?.get('uuid') || '',
      grc_organisational_unit: record?.get('operation')?.get('uuid') || '',

      created__gte: record?.get('observation_date__gte')?.toISOString() || '',
      lead_observer: record.get('lead_observer')?.get('uuid') || '',

      CubeObservation___framework:
        record.get('site_framework')?.get('uuid') || '',
      CubeObservation___site_association__risk_owner:
        record.get('site_association__risk_owner')?.get('uuid') || '',

      CubeObservation___site_control:
        record.get('site_control')?.get('uuid') || '',
      CubeObservation___site_control_owner:
        record.get('site_control_owner')?.get('uuid') || '',

      FieldControlConfirmation__has_actions: asBooleanStringOrEmptyString(
        record.get('are_follow_up_actions_required'),
      ),
      CriticalControlCheck__has_fixed: asBooleanStringOrEmptyString(
        record.get('CriticalControlCheck__has_fixed'),
      ),
      CriticalControlCheck__has_interruption: asBooleanStringOrEmptyString(
        record.get('CriticalControlCheck__has_interruption'),
      ),

      page: record?.get('page') ?? '1',
      page_size: record?.get('page_size') ?? '100',
    };
    return Map(params) as Map<keyof typeof params, string>;
  }

  static toParams(record: ActivityFilterEntityRecord) {
    const params = new URLSearchParams(this.asMappedParams(record).toJS());
    return params.toString();
  }

  static onChangeClean(
    record: ActivityFilterEntityRecord,
    evt: FormChangeEvent,
    rootName: string,
  ) {
    const { name, value } = evt.target;

    if (name === rootName) return value as ActivityFilterEntityRecord;
    // when a field changes, reset to page 1 to avoid requesting out of range results from API
    const updatedRecord = name != 'page' ? record.set('page', '1') : record;

    // Warning does not work with nested updates
    switch (name) {
      case 'risk':
        return updatedRecord.withMutations((rec) => {
          // Reset all fields which are filtered by risk
          // (not: asset, operation, period, lead_observer).
          // These fields should be disabled if no risk is set
          // (as they'll be wiped when Risk changes)
          rec.set('site_framework', null);
          rec.set('site_association__risk_owner', null);
          rec.set('site_control', null);
          rec.set('site_control_owner', null);
          rec.set(name, value);
        });
      case 'site_framework':
        return updatedRecord.withMutations((rec) => {
          rec.set('site_control', null);
          rec.set(name, value);
        });
      case 'asset':
        return updatedRecord.withMutations((rec) => {
          rec.set('operation', null);
          rec.set(name, value);
        });
      case 'observation_types':
        return updatedRecord.withMutations((rec) => {
          rec.set('are_follow_up_actions_required', null);
          rec.set(name, value);
        });
      default:
        return updatedRecord.set(name, value);
    }
  }
}

export type ActivityFilterEntityFields = {
  risk: RiskEntityRecord;
  observation_types: ObservationType[];
  asset: GrcOrganisationalUnitEntityRecord;
  operation: GrcOrganisationalUnitEntityRecord;
  observation_date__gte: Moment;
  lead_observer: PersonEntityRecord;
  site_framework: SiteFrameworkSlimEntityRecord;
  /**
   * SiteFramework risk owner, per the My control framework dashboard.
   * This field supports the hidden field in the My Control Frameworks page that
   * defaults to the currently logged in user.
   */
  site_association__risk_owner: PersonEntityRecord;
  site_control: SiteControlEntityRecord;
  site_control_owner: PersonEntityRecord;

  CriticalControlCheck__has_fixed: boolean | null;
  CriticalControlCheck__has_interruption: boolean | null;
  are_follow_up_actions_required: boolean | null;

  page_size: string;
  page: string;
};

export type ActivityFilterEntityRecord =
  EntityRecord<ActivityFilterEntityFields>;

ActivityFilterEntity.duck = new ScreenDuck({
  app: 'Cube',
  entity: ActivityFilterEntity,
  name: 'ActivityFilter',
});

export default ActivityFilterEntity;

/** takes `null | boolean`, returns the stringified bool, or an empty string for `null` */
function asBooleanStringOrEmptyString(value: null | boolean) {
  return value == null ? '' : String(value);
}
