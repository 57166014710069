import { type List } from 'immutable';

import { Entity, Fields } from '@burnsred/entity';
import { default as DjangoRestFramework } from '@burnsred/entity-duck-namespace-drf';
import { type EntityFields, type EntityRecord } from 'types';

import ControlDesignationEntity from './ControlDesignation.ts';
import ControlDomainEntity from './ControlDomain.ts';
import ExistingControlEntity from './ExistingControl.ts';
import HierarchyOfControlEntity from './HierarchyOfControl.ts';
import I18nTextEntity, { toString } from './i18n/I18nText';
import {
  SiteControlC3QuestionEntity,
  type SiteControlC3QuestionEntityRecord,
} from './Question/SiteC3Question.ts';
import {
  SiteControlCCFVQuestionEntity,
  type SiteControlCCFVQuestionEntityRecord,
} from './Question/SiteCCFVQuestion.ts';
import SupportFactorEntity from './SupportFactor.ts';
import TimeZoneEntity from './TimeZone.ts';
import { type BaseControlEntityFields, type TitleEntityFields } from './types';

class SiteControlEntity extends Entity {
  static paginated = true;

  static paths = {
    apiBase: '/cube/v1/site_control/',
  };

  static fields: EntityFields<SiteControlEntityFields> = {
    uuid: new Fields.IdField(),

    title: new Fields.EntityField({
      entity: I18nTextEntity,
    }),

    sitecontrolc3question_set: new Fields.EntityField({
      entity: SiteControlC3QuestionEntity,
      many: true,
      blank: true,
    }),
    configuration: new Fields.CharField({ blank: true }),
    applicable_rules: new Fields.CharField(),
    is_critical: new Fields.BooleanField({ default: false }),
    sitecontrolccfvquestion_set: new Fields.EntityField({
      entity: SiteControlCCFVQuestionEntity,
      many: true,
      blank: true,
    }),
    control_designation: new Fields.EntityField({
      entity: ControlDesignationEntity,
      blank: true,
    }),

    existing_controls: new Fields.EntityField({
      entity: ExistingControlEntity,
      many: true,
      blank: true,
    }),

    timezones: new Fields.EntityField({
      entity: TimeZoneEntity,
      many: true,
    }),

    control_domain: new Fields.EntityField({
      entity: ControlDomainEntity,
    }),

    hierarchy_of_control: new Fields.EntityField({
      entity: HierarchyOfControlEntity,
    }),

    fel_link_number: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    justification: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    features: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    in_scope: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    out_of_scope: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    design_standard: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    operating_standard: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    erosion: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    cause: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    purpose: new Fields.EntityField({
      entity: I18nTextEntity,
      blank: true,
    }),

    count_cc: new Fields.IntegerField(),
    count_ccc: new Fields.IntegerField(),
    count_interruptions: new Fields.IntegerField(),
    count_fix: new Fields.IntegerField(),
    cc_with_actions: new Fields.IntegerField(),
    support_factors: new Fields.EntityField({
      entity: SupportFactorEntity,
      many: true,
    }),
  };

  static toString = toString<SiteControlEntityRecord>;
}

type SiteControlEntityFields = TitleEntityFields &
  BaseControlEntityFields & {
    sitecontrolc3question_set: List<SiteControlC3QuestionEntityRecord>;
    sitecontrolccfvquestion_set: List<SiteControlCCFVQuestionEntityRecord>;
    /**
     * A site control can have one or multiple configurations
     * (6 possible configurations, see SiteFrameworkControl)
     */
    configuration: string;
    is_critical: boolean;
    applicable_rules: string;
    count_cc: number;
    count_ccc: number;
    count_interruptions: number;
    count_fix: number;
    cc_with_actions: number;
  };

export type SiteControlEntityRecord = EntityRecord<SiteControlEntityFields>;

SiteControlEntity.duck = new DjangoRestFramework({
  app: 'Cube',
  entity: SiteControlEntity,
  name: 'SiteControl',
});

export default SiteControlEntity;
