import { type SystemStyleObject, Text } from '@chakra-ui/react';
import { type Map } from 'immutable';

import { VStack } from 'components';
import type { BhpPersonEntityFields } from 'entities/api/Person/BhpPerson';

import { Autocomplete } from '../Autocomplete';
import type { WidgetSelectInputProps } from '../Autocomplete/Autocomplete';

/**
 * Renders an Autocomplete with double-line options which provide more context
 * to distinguish between similarly named Persons.
 */
export const PersonPicker = <
  T extends Map<string, unknown>,
  Many extends boolean = true,
>({
  getOptionLabel: _,
  ...props
}: WidgetSelectInputProps<T, Many>) => {
  return (
    // @ts-expect-error Type 'T | List<T>' is not assignable to type 'List<Map<string, unknown>>'
    <Autocomplete
      menuPortalTarget={undefined}
      getOptionLabel={(option: BhpPersonEntityFields) => {
        // note: there are some Persons missing core info!
        const firstLine = option.user
          ? `${option.first_name} ${option.last_name} (${option.user})`
          : option.uuid;

        const secondLine =
          option?.organisational_unit?.ancestors
            // @ts-expect-error option the result of record.toJS(); TS expects the nested record to be a Map
            ?.map(({ name }) => name)
            ?.join(' | ') ?? '';

        return (
          <VStack gap={0}>
            <Text>{firstLine}</Text>
            <Text sx={secondLineSx}>{secondLine}</Text>
          </VStack>
        );
      }}
      // PersonPicker is taller due to double-line options; display larger by default
      size="lg"
      // @ts-expect-error Property 'chakraStyles' does not exist on type 'Omit<WidgetSelectInputProps<T, Many>, "getOptionLabel">
      chakraStyles={{ ...personPickerChakraStyles, ...props.chakraStyles }}
      {...props}
    />
  );
};

/** display elements for the 'lg' Autocomplete at the same size as 'md' */
const personPickerChakraStyles = {
  /** Box (uses theme styles for Chakra's CloseButton) */
  clearIndicator: (provided: SystemStyleObject) => ({
    ...provided,
    boxSize: 8,
    fontSize: 'xs',
  }),

  /** Box (uses theme styles for Chakra's InputRightAddon) */
  dropdownIndicator: (provided: SystemStyleObject) => ({
    ...provided,
    fontSize: 'xl',
  }),
};

export const secondLineSx: SystemStyleObject = {
  fontSize: 'xs',
  color: 'muted',

  '[data-focus=true] &': { color: 'gray.600' },
  '[aria-selected=true] &': { color: 'white' },
  '[data-focus=true] [aria-selected=true] &': { color: 'white' },
};
