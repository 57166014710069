import { createIcon } from '@chakra-ui/react';
import {
  MdCheckCircleOutline,
  MdLanguage,
  MdNorthEast,
  MdOutlineAddCircleOutline,
  MdOutlineBuildCircle,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineCircle,
  MdOutlineClear,
  MdOutlineContentCopy,
  MdOutlineEqualizer,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineIndeterminateCheckBox,
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineLock,
  MdOutlineMoreHoriz,
  MdOutlinePanoramaWideAngleSelect,
  MdOutlinePlace,
  MdOutlineQueryBuilder,
  MdOutlineShare,
  MdOutlineWarningAmber,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdSouthEast,
  MdWarning,
} from 'react-icons/md';

// BHP Cube Styleguide
// https://app.zeplin.io/project/66556405bc35cbe70b32aff1/styleguide

/**
 * Dict of react-icons used in the project.
 *
 * Re-export allows code completion and documentation.
 *
 * ```tsx
 * // first import icons...
 * import { icons } from 'assets/icons';
 *
 * <Icon as={icons.MdRadioButtonChecked} color="icon" boxSize="1em" />
 * //              ^ ...leverage auto-complete
 * ```
 *
 * @see https://app.zeplin.io/project/66556405bc35cbe70b32aff1/styleguide/components?seid=66557c88fee2b32e71120f30
 * @see https://v2.chakra-ui.com/docs/components/icon/usage#using-a-third-party-icon-library
 */

export const icons = {
  // please sort this list alphabetically
  MdCheckCircleOutline,
  MdLanguage,
  MdNorthEast,
  MdOutlineAddCircleOutline,
  MdOutlineBuildCircle,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineCircle,
  MdOutlineClear,
  MdOutlineContentCopy,
  MdOutlineEqualizer,
  MdOutlineExpandLess,
  MdOutlineExpandMore,
  MdOutlineIndeterminateCheckBox,
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineLock,
  MdOutlineMoreHoriz,
  MdOutlinePanoramaWideAngleSelect,
  MdOutlinePlace,
  MdOutlineQueryBuilder,
  MdOutlineShare,
  MdOutlineWarningAmber,
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdSouthEast,
  MdWarning,
};

/**
 * Can be used like:
 *
 * ```tsx
 * <BHPLogoIcon />
 * <BHPLogoIcon boxSize="12" />
 * <BHPLogoIcon color="red.500" />
 * <Icon as={BHPLogoIcon} color="icon" boxSize="1em" />
 * <IconButton aria-label="Branded button" icon={<BHPLogoIcon />} />
 * ```
 *
 * https://v2.chakra-ui.com/docs/components/icon/usage#using-a-third-party-icon-library
 *
 * Note that in the creation of this icon, we have:
 * - ensured that the viewBox is correct (standard icons should be '0 0 24 24')
 * - set fill to currentColor where required
 * - set displayName to the component name, otherwise shows as "Anonymous" in react-devtools
 */
export const BHPLogoIcon = createIcon({
  displayName: 'BHPLogoIcon',
  viewBox: '0 0 60 23',
  path: (
    <path
      d="M60 7.263c0 4.011-3.237 7.263-7.23 7.263h-4.895V23h-5.623V0H52.77c1.917 0 3.756.765 5.112 2.127A7.28 7.28 0 0 1 60 7.263zm-8.142-2.522h-3.982v4.741h3.982c1.248 0 2.26-1.117 2.26-2.37 0-1.254-1.012-2.37-2.26-2.37zM33.214 0v9.28h-7.833V0h-5.624v23h5.624v-8.474h7.832v8.473h5.624V0h-5.623zm-15.67 16.745c0 3.795-3.37 6.255-7.03 6.255H0V0h10.167c3.73 0 6.828 2.755 6.828 6.156 0 2.69-1.253 4.134-2.985 5.221 2.002.867 3.532 2.163 3.532 5.37l.001-.002zM9.388 13.72H5.623v4.438H9.39c1.456 0 2.26-.993 2.26-2.219s-1.012-2.22-2.26-2.22zM8.842 4.74H5.623v4.741h3.219c1.491 0 2.26-1.117 2.26-2.37 0-1.254-.769-2.37-2.26-2.37z"
      fill="currentColor"
      fillRule="nonzero"
    />
  ),
});
