import type { SystemStyleObject } from '@chakra-ui/react';

export const textAreaSx: SystemStyleObject = {
  minHeight: 32, //equivalent to 128px
  fontSize: 'sm',
  bg: 'gray.100',
};

export const textAreaResizerSx: SystemStyleObject = {
  fontSize: 'sm',
  lineHeight: 'var(--chakra-lineHeights-short)',
  paddingX: 3,
  paddingY: 2,
  borderWidth: '1px',
};

export const gridSx: SystemStyleObject = {
  gridTemplateColumns: { base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' },
  gap: 4,
  paddingTop: 8,
};

export const helperTextSx: SystemStyleObject = {
  color: 'gray.400',
  fontSize: 'xs',
};
