import { Flex, Icon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { icons } from 'assets/icons';

import { iconSx, legendSx } from './ActivityTableLegend.styles';

export function ActivityTableLegend() {
  return (
    <Flex gap={4} sx={legendSx}>
      <Flex gap={1}>
        <Icon as={icons.MdOutlineBuildCircle} sx={iconSx} color="icon" />
        <FormattedMessage
          id="activity-list.fixed-on-spot"
          defaultMessage="Fixed on the spot"
        />
      </Flex>

      <Flex gap={1}>
        <Icon as={icons.MdOutlineWarningAmber} sx={iconSx} color="error" />
        <FormattedMessage
          id="activity-list.interruptions"
          defaultMessage="Interruptions"
        />
      </Flex>
    </Flex>
  );
}
