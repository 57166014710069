import {
  Icon,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { type ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { icons } from 'assets/icons';
import { Tooltip } from 'components';
import {
  type FieldObservationReportRecord,
  observationTypeShortNameTranslations,
} from 'entities/reporting/FieldObservationReport';
import { useLocale } from 'locales/useLocale';
import { settings } from 'settings';

import { iconSx, openLinkSx, tableSx } from './ActivityTable.styles';

export function ActivityTable({ children }: { children?: ReactNode }) {
  return (
    <TableContainer>
      <Table variant="simple" size="sm" sx={tableSx}>
        <Thead>
          <Tr>
            {/* these percentages are proportional... no need to add up to 100 YOLO! */}
            <Th width="5%">
              <FormattedMessage id="activity-list.type" defaultMessage="Type" />
            </Th>
            <Th width="19%">
              <FormattedMessage
                id="activity-list.asset"
                defaultMessage="Asset"
              />
            </Th>
            <Th width="19%">
              <FormattedMessage
                id="activity-list.operation"
                defaultMessage="Operation"
              />
            </Th>
            <Th width="23%">
              <FormattedMessage
                id="activity-list.control-framework-title"
                defaultMessage="Control framework title"
              />
            </Th>
            <Th width="12%">
              <FormattedMessage
                id="activity-list.lead-observer"
                defaultMessage="Lead observer"
              />
            </Th>
            <Th width="8%" className="center">
              <FormattedMessage
                id="activity-list.actions"
                defaultMessage="Actions"
              />
            </Th>
            <Th width="8%" className="center">
              <FormattedMessage
                id="activity-list.fix-inter"
                defaultMessage="Fix/Inter."
              />
            </Th>
            <Th width="8%">
              <FormattedMessage id="activity-list.date" defaultMessage="Date" />
            </Th>
            <Th width="6%" />
          </Tr>
        </Thead>

        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}

type ActivityTableRowProps = {
  record: FieldObservationReportRecord;
};

export function ActivityTableRow({ record }: ActivityTableRowProps) {
  const { formatMessage } = useIntl();
  const { toString } = useLocale();

  const grc_organisational_unit = record?.get('grc_organisational_unit');
  // Note(Tom): Flex has a really nice generic tree entity with support to compute a cached tree with tree like
  // methods, consider moving the abstract entity tree implementation into cube to support
  // common tree operations. See TreeBaseEntityClass in flex.
  const operation = useMemo(() => {
    // There are two direct ancestors, I'm at level 3
    if (grc_organisational_unit?.get('ancestors')?.size == 2) {
      return grc_organisational_unit;
    }
    return undefined;
  }, [grc_organisational_unit]);
  const asset = useMemo(() => {
    // operation is known, get its parent.
    if (operation) {
      return operation
        ?.get('ancestors')
        ?.find((val) => val.get('uuid') === operation.get('parent'));
    }
    // I have 1 ancestor, I am an asset.
    else if (grc_organisational_unit?.get('ancestors')?.size == 1) {
      return grc_organisational_unit;
    }
    return undefined;
  }, [grc_organisational_unit, operation]);
  const leadObserver = [
    record?.get('lead_observer')?.get('first_name'),
    record?.get('lead_observer')?.get('last_name'),
  ].join(' ');

  const actionsCount = record?.get('actions_count')
    ? record?.get('actions_count')
    : '';

  const fixesCount = record.get('fixes_count');
  const interruptionsCount = record.get('interruptions_count');

  const iconTitle =
    fixesCount || interruptionsCount
      ? `Interruptions: ${record.get('interruptions_count')}, Fixes: ${record.get('fixes_count')}`
      : undefined;

  const shortName = record.get('observation_type_short_name');
  const observationTypeShortName = shortName
    ? formatMessage(observationTypeShortNameTranslations[shortName])
    : '';

  return (
    <Tr
      key={record.get('uuid')}
      className={interruptionsCount ? 'isInterruption' : ''}
    >
      <Td>{observationTypeShortName}</Td>
      {/* @ts-expect-error  Incompatible signatures */}
      <Td>{asset?.get('name')}</Td>
      <Td>{operation?.get('name')}</Td>
      <Td>
        {
          // @ts-expect-error Property 'title' is missing in type 'FieldObservationReportFields' but required in type 'TitleEntityFields'.
          toString(record, 'control_framework_title')
        }
      </Td>
      <Td>{leadObserver}</Td>
      <Td className="center">{actionsCount}</Td>
      <Td className="center">
        <>
          {interruptionsCount > 0 && (
            <Tooltip label={iconTitle}>
              <Text as="span">
                <Icon
                  as={icons.MdOutlineWarningAmber}
                  sx={iconSx}
                  color={'error'}
                />
                {false &&
                  iconTitle &&
                  `${record.get('interruptions_count')}/${record.get('fixes_count')}`}
              </Text>
            </Tooltip>
          )}
          {fixesCount > 0 && (
            <Tooltip label={iconTitle}>
              <Text as="span">
                <Icon
                  as={icons.MdOutlineBuildCircle}
                  sx={iconSx}
                  color={'icon'}
                />
                {false &&
                  iconTitle &&
                  `${record.get('interruptions_count')}/${record.get('fixes_count')}`}
              </Text>
            </Tooltip>
          )}
        </>
      </Td>
      <Td layerStyle="noWrap">
        {record.get('observation_date').format('DD.MM.YYYY')}
      </Td>
      <Td>
        <Link
          href={`${settings.FLEX_URL}/observation/${record.get('observation_type_short_name')}/${record.get('uuid')}`}
          target="_blank"
          sx={openLinkSx}
        >
          <FormattedMessage
            id="activity-list.control-framework-open-link"
            defaultMessage="Open"
          />
        </Link>
      </Td>
    </Tr>
  );
}
