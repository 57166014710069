import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import type { ReactElement } from 'react';
import { Fragment } from 'react/jsx-runtime';

import { icons } from 'assets/icons';
import { Tooltip } from 'components';

import {
  cardSx,
  headingSx,
  statRowFlexSx,
  statRowLabelSx,
  statRowMetricSx,
  trendStatChangeSx,
  trendStatIconSx,
  trendStatMetricSx,
  trendStatSx,
} from './PerformanceCard.style';

type Metric = {
  metric: number | string;
  label?: ReactElement;
  onClick?: () => void;
};

type Stat = {
  /**
   * pass either:
   * - a simple metric (`1234`) or
   * - a composed metric, eg: `'123 / 500'`
   */
  metric: number | string | Metric[];
  /** should be FormattedMessage */
  label: ReactElement;
  /** only applicable to TrendStat */
  change?: number;
  /** only applicable to TrendStat; should be FormattedMessage */
  changeLabel?: ReactElement;
  onClick?: () => void;
};

const StatRow = ({ metric, label, onClick }: Stat) => {
  return (
    <Flex as="figure" sx={statRowFlexSx} onClick={onClick}>
      {Array.isArray(metric) ? (
        <Box sx={statRowMetricSx}>
          {metric?.map((metricNode, index) => (
            <Fragment key={index}>
              <Tooltip label={metricNode.label ?? label}>
                <Text
                  as="span"
                  key={index}
                  onClick={metricNode.onClick}
                  layerStyle={
                    typeof metricNode.onClick === 'function'
                      ? 'hoverPointer'
                      : undefined
                  }
                >
                  {metricNode.metric}
                </Text>
              </Tooltip>
              {index != metric.length - 1 && ' / '}
            </Fragment>
          ))}
        </Box>
      ) : (
        <Box sx={statRowMetricSx}>
          <Tooltip label={label}>
            <Text
              as="span"
              onClick={onClick}
              layerStyle={
                typeof onClick === 'function' ? 'hoverPointer' : undefined
              }
            >
              {metric}
            </Text>
          </Tooltip>
        </Box>
      )}
      <Text as="figcaption" sx={statRowLabelSx}>
        {label}
      </Text>
    </Flex>
  );
};

const TrendStat = ({ metric, label, change, changeLabel }: Stat) => {
  return (
    <Flex sx={trendStatSx}>
      <figure>
        <Tooltip label={label}>
          {Array.isArray(metric) ? (
            <Box sx={trendStatMetricSx}>
              {metric?.map((metricNode, index) => (
                <Fragment key={index}>
                  <Text
                    as="span"
                    key={index}
                    onClick={metricNode.onClick}
                    layerStyle={
                      typeof metricNode.onClick == 'function'
                        ? 'hoverPointer'
                        : undefined
                    }
                  >
                    {metricNode.metric}
                  </Text>
                  {index != metric.length - 1 && ' / '}
                </Fragment>
              ))}
            </Box>
          ) : (
            <Box sx={trendStatMetricSx}>{metric}</Box>
          )}
        </Tooltip>

        <VisuallyHidden>
          <Text as="figcaption">{label}</Text>
        </VisuallyHidden>
      </figure>

      {change && (
        <figure>
          <Tooltip label={changeLabel} hasArrow>
            <Box sx={trendStatChangeSx}>{change}</Box>
          </Tooltip>

          <VisuallyHidden>
            <Text as="figcaption">{changeLabel}</Text>
          </VisuallyHidden>

          <Icon
            as={change > 0 ? icons.MdNorthEast : icons.MdSouthEast}
            role="none presentation"
            sx={trendStatIconSx}
          />
        </figure>
      )}
    </Flex>
  );
};

export type PerformanceCardProps = {
  /** Should be a FormattedMessage */
  heading: ReactElement;
  stats: [Stat] | [Stat, Stat];
  onClick?: (params: unknown) => void;
};

/**
 * Renders a Card displaying performance statistics in one of two styles
 * depending on the type of Stats passed:
 * - `TrendStat`: rendered if a single Stat is passed (metric, label, change, changeLabel)
 * - `StatRows`: rendered if 2 Stats are passed (metric, label)
 */
export const PerformanceCard = ({
  heading,
  stats,
  onClick,
}: PerformanceCardProps) => {
  return (
    <Card sx={cardSx} onClick={onClick} className={onClick ? 'is-linked' : ''}>
      <CardHeader>
        <Heading as="h3" textStyle="h4" sx={headingSx}>
          {heading}
        </Heading>
      </CardHeader>

      <CardBody>
        {stats.map((stat, i, allStats) =>
          allStats.length == 1 ? (
            <TrendStat key={i} {...stat} />
          ) : (
            <StatRow key={i} {...stat} />
          ),
        )}
      </CardBody>
    </Card>
  );
};
