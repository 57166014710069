import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';

import { withQuery } from '@burnsred/entity-duck-query';
import { VStack } from 'components';
import ControlEntity, { type ControlEntityRecord } from 'entities/api/Control';
import { useLocale } from 'locales/useLocale';
import { type ActionsContext } from 'screens/control-frameworks/ControlFramework.hooks.ts';
import type { WithQueryReturnProps } from 'types';
import { createLogger } from 'util/createLogger';

const log = createLogger('GlobalFrameworkControlDetail');

export type GlobalControlDetailContextType = {
  controlFormControls: GlobalFrameworkControlDetailProps;
};

const pathMap = {
  design: 0,
  rules: 1,
  ccc: 2,
  fcc: 3,
  cco: 4,
  la: 5,
} as const;

export type TabPathSegment = keyof typeof pathMap;

/** derive previous tab path segment from pathMap, eg: { rules: 'design', ... } */
export const prevTabMap = Object.entries(pathMap).reduce(
  (acc, [key, index], _, arr) => ({
    ...acc,
    // prettier-ignore
    ...(
      // special-case settings, which navigates up to .../controls/
      key === 'design' ? { [key]: '..' } :
        // workaround: CCO & LA are disabled in MVP
        key === 'fcc' ? { [key]: 'ccc' } :
          // otherwise, target the previous tab
          { [key]: arr[index - 1][0] }
    ),
  }),
  {} as Record<TabPathSegment, string>,
);

/** derive next tab path segment from pathMap, eg: { design: 'rules', ... } */
export const nextTabMap = Object.entries(pathMap).reduce(
  (acc, [key, index], _, arr) => ({
    ...acc,
    // prettier-ignore
    ...(
      // special-case settings, which navigates up to .../controls/
      key === 'la' ? { [key]: '..' } :
          // otherwise, target the previous tab
          { [key]: arr[index + 1][0] }
    ),
  }),
  {} as Record<TabPathSegment, string>,
);

type GlobalFrameworkControlDetailProps =
  WithQueryReturnProps<ControlEntityRecord>;

function GlobalFrameworkControlDetail(
  props: GlobalFrameworkControlDetailProps,
) {
  const { toString } = useLocale();
  const navigate = useNavigate();
  const { actions, setActions } = useOutletContext<ActionsContext>();

  const { pathname } = useLocation();
  const { controlUuid, globalFrameworkUuid } = useParams();

  const isNew = controlUuid?.toLowerCase() == 'new';

  const tabSegment =
    pathname
      // the path segment immediately after the controlUuid...
      .split(`${controlUuid}/` as string)
      .at(-1)
      // ...dropping trailing path segments, defaulting to 'settings'
      ?.split('/')[0] ?? 'settings';
  const currentTab = pathMap[tabSegment as keyof typeof pathMap];

  log('%o', props);

  useEffect(() => {
    const lastPathSegment = pathname.split(`${controlUuid}/`).at(-1);
    const targetBase = `/global-frameworks/${globalFrameworkUuid}/controls/${controlUuid}/`;

    setActions({
      ...actions,
      previous: () =>
        navigate(targetBase + prevTabMap[lastPathSegment as TabPathSegment]),
      next: () =>
        navigate(targetBase + nextTabMap[lastPathSegment as TabPathSegment]),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /** type safe navigation factory */
  const createHandleNavigate = (slug: TabPathSegment) => () =>
    void navigate(slug);

  const context = {
    controlFormControls: props,
  } satisfies GlobalControlDetailContextType;

  return (
    <Flex layerStyle="cardLike">
      <VStack gap={2}>
        <Heading size="xs" variant="primary">
          {isNew ? (
            <FormattedMessage
              id="global-framework.controls.detail.create-new-control"
              defaultMessage="Create a new control"
            />
          ) : (
            toString(props.value)
          )}
        </Heading>

        <Text fontSize="xs" color="muted">
          <FormattedMessage
            id="global-framework.controls.detail.help-text-for-this-risk-architecture"
            defaultMessage="For this global control framework"
          />
        </Text>
      </VStack>

      <Tabs
        index={currentTab} // control via routing
        isLazy // only load the selected tab
        variant="line"
      >
        <TabList>
          <Tab onClick={createHandleNavigate('design')}>
            <FormattedMessage
              id="global-framework.controls.detail.tab.design"
              defaultMessage="Control Design"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('rules')}>
            <FormattedMessage
              id="global-framework.controls.detail.tab.rules"
              defaultMessage="Applicability"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('ccc')}>
            <FormattedMessage
              id="global-framework.controls.detail.tab.ccc"
              defaultMessage="CCC"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('fcc')}>
            <FormattedMessage
              id="global-framework.controls.detail.tab.fcc"
              defaultMessage="FCC"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('cco')} isDisabled>
            <FormattedMessage
              id="global-framework.controls.detail.tab.cco"
              defaultMessage="CCO"
            />
          </Tab>
          <Tab onClick={createHandleNavigate('la')} isDisabled>
            <FormattedMessage
              id="global-framework.controls.detail.tab.la"
              defaultMessage="LA"
            />
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
          <TabPanel>
            <Outlet context={context} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default withQuery(() => {
  const { controlUuid } = useParams();
  return {
    action: ControlEntity.duck.actions.get({ id: controlUuid }),
  };
})(GlobalFrameworkControlDetail);
