import { VStack } from 'components';

import { GlobalFrameworkControlDocumentation } from './GlobalFrameworkControlDocumentation';
import { GlobalFrameworkControlSettings } from './GlobalFrameworkControlSettings';
import type { GlobalControlDetailContextType } from '../GlobalFrameworkControlDetail';

export const GlobalFrameworkControlDesign = ({
  controlFormControls,
}: GlobalControlDetailContextType) => {
  return (
    <VStack>
      <GlobalFrameworkControlSettings
        controlFormControls={controlFormControls}
      />

      <GlobalFrameworkControlDocumentation
        controlFormControls={controlFormControls}
      />
    </VStack>
  );
};
