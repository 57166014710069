import { SimpleGrid } from '@chakra-ui/react';
import { type Map } from 'immutable';

import { SimpleEntityList } from 'components';
import type { PersonEntityRecord } from 'entities/api/Person/Person';
import SiteFrameworkEntity from 'entities/api/SiteFramework';

import { FrameworkCard } from './FrameworkCard';

export type FrameworksListProps = {
  filterRecord?: Map<string, unknown>;
  params: Map<string, string>;
  refreshList?: number;
  showStats?: boolean;
  risk_owner?: PersonEntityRecord;
  site_control_owner?: PersonEntityRecord;
};

export const FrameworksList = ({
  filterRecord,
  params,
  refreshList = 0,
  showStats = false,
  risk_owner,
  site_control_owner,
}: FrameworksListProps) => {
  return (
    <SimpleGrid
      position="relative"
      templateColumns="repeat(auto-fill, minmax(290px, 1fr))"
      spacing={6}
      minHeight={12}
    >
      <SimpleEntityList
        Entity={SiteFrameworkEntity}
        RecordComponent={FrameworkCard}
        recordComponentProps={{
          showStats,
          filterRecord,
          risk_owner,
          site_control_owner,
        }}
        params={params}
        refreshList={refreshList}
      />
    </SimpleGrid>
  );
};
