import { type SystemStyleObject } from '@chakra-ui/react';

export const legendSx: SystemStyleObject = {
  alignItems: 'center',
  fontSize: 'sm',
  color: 'gray.600',
};

export const iconSx: SystemStyleObject = {
  boxSize: 5,
};
