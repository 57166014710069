import { useCallback, useMemo } from 'react';
import { type defineMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { type PaginatorProps, type PaginatorState } from 'components';
import { FieldObservationReport, usePagination } from 'entities';
import type { SiteFrameworkEntityRecord } from 'entities/api/SiteFramework';
import ActivityFilterEntity, {
  type ActivityFilterEntityFields,
  type ActivityFilterEntityRecord,
} from 'entities/filter/ActivityFilter';
import {
  type ObservationType,
  observationTypeShortNameTranslations,
  observationTypes,
} from 'entities/reporting/FieldObservationReport';
import { useEntityFilterControls } from 'forms/hooks/filters';
import type { Mutable } from 'types';
import { createLogger } from 'util/createLogger';

import { ACTIVITY_LIST_URL } from '../activity-list-paths';

const log = createLogger('useActivityListControls');

export const observationTypeOptions = [
  {
    value: 'CriticalControlCheck',
    label: observationTypeShortNameTranslations['CCC'],
  },
  {
    value: 'ControlConfirmation',
    label: observationTypeShortNameTranslations['FCC'],
  },
] satisfies {
  value: ObservationType;
  label: ReturnType<typeof defineMessage>;
}[];

export type UseActivityListControlsReturn = ReturnType<
  typeof useActivityListControls
>;

export function useActivityListControls() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { name, filterRecord, onChange, FilterField, errors } =
    useEntityFilterControls<
      typeof ActivityFilterEntity,
      ActivityFilterEntityRecord
    >(
      ActivityFilterEntity,
      'ActivityFilters',
      ActivityFilterEntity.onChangeClean,
    );

  // memoise params to prevent a duplicate API call triggered by useSelector call
  const params = useMemo(
    () => ActivityFilterEntity.asMappedParams(filterRecord),
    [filterRecord],
  );

  const pagination = usePagination(FieldObservationReport, params);

  const onChangePagination = ({ page, page_size }: PaginatorState) => {
    // note that filterRecord stores page & page_size as strings, and Paginator requires numbers
    const newRecord = filterRecord.withMutations((rec) => {
      rec.set('page', String(page) || '1');
      rec.set('page_size', String(page_size) || '6');
    });
    onChange({
      target: { name, value: newRecord },
    });
  };

  const paginatorProps: PaginatorProps = {
    page: parseInt(filterRecord?.get('page') ?? '1'),
    page_size: parseInt(filterRecord?.get('page_size') ?? '100'),
    totalItems: pagination?.get('count') ?? 0,
    onChange: onChangePagination,
    pageSizeOptions: [100, 200, 300],
  };

  const onChangeObservationType = useCallback(
    (value: ObservationType[]) => {
      // don't permit both unchecked
      const newValue = value.length ? value : observationTypes;
      const newRecord = filterRecord.set('observation_types', newValue);
      dispatch(ActivityFilterEntity.duck.actions.save(newRecord));
    },
    [dispatch, filterRecord],
  );

  /**
   * Note:
   * - when navigating from a Stats card, it is not possible to derive a
   *   single Risk; the user will be required to select a Risk before
   *   results are shown.
   * - when navigating from a ControlCardWithStats, it is not possible to
   *   derive a single framework.
   */
  const createHandleNavigateToActivityList = useCallback(
    (params: Partial<ActivityFilterEntityFields>) => () => {
      const newRecord = filterRecord.withMutations((newRecord) => {
        log('createHandleNavigateToActivityList', params);

        for (const key of Object.keys(params)) {
          const value = params[key as keyof ActivityFilterEntityFields];
          newRecord.set(key, value);

          // extract the risk from params if it's not present
          if (!params.risk && key == 'site_framework') {
            const risk = (value as SiteFrameworkEntityRecord)?.get('risk');
            newRecord.set('risk', risk);
          }
        }
      });
      dispatch(ActivityFilterEntity.duck.actions.save(newRecord));

      // Note: navigation setting filters via URL params is out of scope for
      // now, pending a universal solution which addresses previous shortcomings
      // with syncing filters history state
      navigate(ACTIVITY_LIST_URL);
    },
    [dispatch, filterRecord, navigate],
  );

  return {
    name,
    filterRecord,
    params,
    onChange,
    FilterField,
    errors,
    paginatorProps,
    onChangeObservationType,
    createHandleNavigateToActivityList,
  };
}

type FieldsToNull = {
  [key in
    | 'CriticalControlCheck__has_fixed'
    | 'CriticalControlCheck__has_interruption'
    | 'are_follow_up_actions_required']: null;
};
type FieldsToUndefine = {
  [key in keyof Omit<
    ActivityFilterEntityFields,
    keyof FieldsToNull | 'observation_types'
  >]: undefined;
};
type ObservationTypesDefault = {
  observation_types: Mutable<typeof observationTypes>;
};
type ActivityFilterEntityReset = ObservationTypesDefault &
  FieldsToUndefine &
  FieldsToNull;

export const activityListFiltersReset = {
  risk: undefined,
  asset: undefined,
  operation: undefined,
  observation_date__gte: undefined,

  site_framework: undefined,
  site_association__risk_owner: undefined,
  site_control: undefined,
  site_control_owner: undefined,
  lead_observer: undefined,

  observation_types: observationTypes as Mutable<typeof observationTypes>,
  CriticalControlCheck__has_fixed: null,
  CriticalControlCheck__has_interruption: null,
  are_follow_up_actions_required: null,

  page: undefined,
  page_size: undefined,
} satisfies ActivityFilterEntityReset;
